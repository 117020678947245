import React, { useState, useEffect } from 'react';
import LeaderboardChild from './LeaderboardChild';
import './Leaderboard.css';
import LeaderboardPlaceholder from '../utils/LeaderboardPlaceholder';
import axios from 'axios';

const Leaderboard = () => {
    const [data, setData] = useState([]);
    const [loadingStates, setLoadingStates] = useState({
        all: true,
        sa: true,
        ga: true,
        mga: true,
        rga: true,
    });
    const [error, setError] = useState(null);
    const [reportDates, setReportDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [defaultDate, setDefaultDate] = useState('');
    const [reportType, setReportType] = useState('Weekly Recap'); // Default to Weekly Recap

    const calculateDateRange = (date) => {
        const formatToMMDDYYYY = (dateObj) => {
            const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
            const dd = String(dateObj.getDate()).padStart(2, '0');
            const yyyy = dateObj.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        const reportDate = new Date(date);
        const startDate = new Date(reportDate);
        startDate.setDate(reportDate.getDate() - 3);

        const endDate = new Date(reportDate);
        endDate.setDate(reportDate.getDate() + 3);

        return {
            startDate: formatToMMDDYYYY(startDate),
            endDate: formatToMMDDYYYY(endDate),
        };
    };

    const fetchReportDates = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/getReportDates');
            if (response.data.success) {
                setReportDates(response.data.reportDates);
                setDefaultDate(response.data.defaultDate);
                setSelectedDate(response.data.defaultDate); // Set the default selected date
            } else {
                console.error('Failed to fetch report dates');
            }
        } catch (error) {
            console.error('Error fetching report dates:', error);
        }
    };

    const fetchData = async (endpoint, params) => {
        try {
            const queryParams = new URLSearchParams(params).toString();
            const response = await axios.get(`http://localhost:3000/api/${endpoint}?${queryParams}`);
            return response.data.success ? response.data.data : [];
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
            return [];
        }
    };

    const reformatName = (name) => {
        if (!name) return "N/A";
        const parts = name.split(" ");
        if (parts.length === 2) return `${parts[1]} ${parts[0]}`;
        if (parts.length === 3) return `${parts[1]} ${parts[0]}`;
        if (parts.length === 4) return `${parts[1]} ${parts[0]} ${parts[3]}`;
        return name;
    };

    const prepareData = (rows) => {
        return rows
            .map((row) => ({
                ...row,
                reformattedName: reformatName(row.LagnName),
                profilePic: row.profpic?.includes("via.placeholder.com")
                    ? reformatName(row.LagnName).charAt(0)
                    : row.profpic,
                alpValue: row.LVL_1_NET || row.LVL_2_NET || row.LVL_3_NET || "N/A",
            }))
            .sort((a, b) => {
                const aValue = a.LVL_1_NET || a.LVL_2_NET || a.LVL_3_NET || 0;
                const bValue = b.LVL_1_NET || b.LVL_2_NET || b.LVL_3_NET || 0;
                return bValue - aValue; // Descending order
            });
    };

    const loadLeaderboardData = async (title, endpoint, key) => {
        const { startDate, endDate } = calculateDateRange(selectedDate);

        try {
            const rows = await fetchData(endpoint, { startDate, endDate, report: reportType });

            setData((prevData) => {
                const existingIndex = prevData.findIndex((board) => board.title === title);
                const newBoard = { title, rows: prepareData(rows) };

                if (existingIndex > -1) {
                    const updatedData = [...prevData];
                    updatedData[existingIndex] = newBoard;
                    return updatedData;
                }

                return [...prevData, newBoard];
            });
        } catch (err) {
            setError(`Failed to fetch data for ${title}.`);
        } finally {
            setLoadingStates((prev) => ({ ...prev, [key]: false }));
        }
    };

    useEffect(() => {
        fetchReportDates();
    }, []);

    useEffect(() => {
        if (selectedDate) {
            setLoadingStates({
                all: true,
                sa: true,
                ga: true,
                mga: true,
                rga: true,
            });

            loadLeaderboardData('All Data', 'getweeklyall', 'all');
            loadLeaderboardData('Top SAs', 'getweeklysa', 'sa');
            loadLeaderboardData('Top GAs', 'getweeklyga', 'ga');
            loadLeaderboardData('Top MGAs', 'getweeklymga', 'mga');
            loadLeaderboardData('Top RGAs', 'getweeklyrga', 'rga');
        }
    }, [selectedDate, reportType]);

    const sortedData = [
        { title: 'All Data', key: 'all' },
        { title: 'Top SAs', key: 'sa' },
        { title: 'Top GAs', key: 'ga' },
        { title: 'Top MGAs', key: 'mga' },
        { title: 'Top RGAs', key: 'rga' },
    ].map((item) => ({
        ...item,
        rows: data.find((board) => board.title === item.title)?.rows || [],
    }));

    const formatToMMDDYYYY = (dateStr) => {
        const date = new Date(dateStr);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${mm}/${dd}/${yyyy}`;
    };

    const handlePreviousDate = () => {
        const currentIndex = reportDates.indexOf(selectedDate);
        if (currentIndex > 0) {
            setSelectedDate(reportDates[currentIndex - 1]);
        }
    };

    const handleNextDate = () => {
        const currentIndex = reportDates.indexOf(selectedDate);
        if (currentIndex < reportDates.length - 1) {
            setSelectedDate(reportDates[currentIndex + 1]);
        }
    };

    return (
        <div>
            {/* Tabs for selecting report type */}
            <div className="tabs" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <input
                    type="radio"
                    id="weekly"
                    name="report_type"
                    value="Weekly Recap"
                    checked={reportType === 'Weekly Recap'}
                    onChange={() => setReportType('Weekly Recap')}
                />
                <label htmlFor="weekly">Week</label>
                <input
                    type="radio"
                    id="mtd"
                    name="report_type"
                    value="MTD Recap"
                    checked={reportType === 'MTD Recap'}
                    onChange={() => setReportType('MTD Recap')}
                />
                <label htmlFor="mtd">MTD</label>
                <input
                    type="radio"
                    id="ytd"
                    name="report_type"
                    value="YTD Recap"
                    checked={reportType === 'YTD Recap'}
                    onChange={() => setReportType('YTD Recap')}
                />
                <label htmlFor="ytd">YTD</label>
            </div>

            {/* Dropdown for selecting report date */}
            <div className="date-select-container" style={{ textAlign: 'center', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button onClick={handlePreviousDate} style={{ marginRight: '10px', padding: '5px' }}>&lt;</button>
                <select
                    id="report-date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    style={{ margin: '0 10px', padding: '5px' }}
                >
                    {reportDates.map((date) => (
                        <option key={date} value={date}>
                            {formatToMMDDYYYY(date)}
                        </option>
                    ))}
                </select>
                <button onClick={handleNextDate} style={{ marginLeft: '10px', padding: '5px' }}>&gt;</button>
            </div>

            {/* Leaderboard Layout */}
            <div className="leaderboard-layout">
                <div className="leaderboard-left">
                    {sortedData
                        .filter((board) => board.title === 'All Data')
                        .map((board, index) =>
                            loadingStates[board.key] ? (
                                <LeaderboardPlaceholder key={index} title={board.title} />
                            ) : (
                                <LeaderboardChild key={index} title={board.title} rows={board.rows} />
                            )
                        )}
                </div>

                <div className="leaderboard-right">
                    {sortedData
                        .filter((board) => board.title !== 'All Data')
                        .map((board, index) =>
                            loadingStates[board.key] ? (
                                <LeaderboardPlaceholder key={index} title={board.title} />
                            ) : (
                                <LeaderboardChild key={index} title={board.title} rows={board.rows} />
                            )
                        )}
                </div>
            </div>
        </div>
    );
};

export default Leaderboard;
