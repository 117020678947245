import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Scorecard.css";

const Scorecard = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
const [lastYear, setLastYear] = useState(currentYear - 1);

// Handler to update years based on navigation
const navigateYears = (direction) => {
  if (direction === "back") {
    setCurrentYear((prev) => {
      const newYear = prev - 1;
      setLastYear(newYear - 1);
      return newYear;
    });
  } else if (direction === "forward") {
    setCurrentYear((prev) => {
      const newYear = prev + 1;
      setLastYear(newYear - 1);
      return newYear;
    });
  }
};

  const [vipsData, setVipsData] = useState([]);
  const [associatesData, setAssociatesData] = useState([]);
  const [alpData, setAlpData] = useState([]);
  const [hiresData, setHiresData] = useState([]);
  const [subAgentData, setSubAgentData] = useState([]);
  const [selectedCellData, setSelectedCellData] = useState(null); // New state
  const [selectedTableDetails, setSelectedTableDetails] = useState({}); // Track details per table
  const [hierarchyData, setHierarchyData] = useState([]);
  const [filteredHierarchyData, setFilteredHierarchyData] = useState([]); // Filtered hierarchy data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const agnName = localStorage.getItem("agnName");
  const [selectedView, setSelectedView] = useState("a"); // Track the selected view

  const toggleView = (view) => {
    setSelectedView(view);
  };

  const formatName = (name) => {
    if (!name) return null;
    const parts = name.split(" ");
    if (parts.length === 4) {
      const [last, first, middle, suffix] = parts;
      return `${first} ${middle} ${last} ${suffix}`;
    }
    if (parts.length === 3) {
      const [last, first, middle] = parts;
      return `${first} ${last} `;
    }
    return name; // Return as-is if it doesn't match expected patterns
  };
  
  const formatCurrency = (amount) => {
    if (!amount || isNaN(amount)) return null;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";
    return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };
  
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Q1 ",
    "Apr",
    "May",
    "Jun",
    "Q2 ",
    "Jul",
    "Aug",
    "Sep",
    "Q3 ",
    "Oct",
    "Nov",
    "Dec",
    "Q4 ",
    "YTD ",
  ];

  const monthLabels = [
    "Jan", "Feb", "Mar",  // index 0,1,2
    "Apr", "May", "Jun",  // index 3,4,5
    "Jul", "Aug", "Sep",  // index 6,7,8
    "Oct", "Nov", "Dec",  // index 9,10,11
  ];

  const previousYear = currentYear - 1;

  const groupByMonthAndYear = (data, dateField) => {
    return data.reduce((acc, item) => {
      const date = new Date(item[dateField]);
      const month = date.getMonth();
      const year = date.getFullYear();

      if (!acc[year]) acc[year] = Array(12).fill(0);
      acc[year][month] += 1; // Increment the count for that month

      return acc;
    }, {});
  };

  const calculateQuarterSums = (monthlyData) => {
    const q1 = monthlyData.slice(0, 3).reduce((sum, value) => sum + value, 0);
    const q2 = monthlyData.slice(3, 6).reduce((sum, value) => sum + value, 0);
    const q3 = monthlyData.slice(6, 9).reduce((sum, value) => sum + value, 0);
    const q4 = monthlyData.slice(9, 12).reduce((sum, value) => sum + value, 0);
    const total = q1 + q2 + q3 + q4;

    return [...monthlyData.slice(0, 3), q1, ...monthlyData.slice(3, 6), q2, ...monthlyData.slice(6, 9), q3, ...monthlyData.slice(9, 12), q4, total];
  };

  const processAlpData = (data, userRole) => {
    const column =
      userRole === "AGT"
        ? "LVL_1_NET"
        : userRole === "SA"
        ? "LVL_2_NET"
        : "LVL_3_NET";
  
    const groupedData = data.reduce((acc, row) => {
      const [month, year] = row.month.split("/").map(Number); // Parse "mm/yyyy" format
      const adjustedMonth = month - 1; // Convert 1-based month to 0-based index
  
      if (!acc[year]) acc[year] = Array(12).fill(0); // Initialize year with empty months
  
      const value = parseFloat(row[column]?.replace(/[^\d.-]/g, "")) || 0;
  
      if (acc[year][adjustedMonth] === 0 || row.CL_Name === "MGA") {
        acc[year][adjustedMonth] = value;
      }
  
      return acc;
    }, {});
  
    return groupedData;
  };
  
  
  const processSubmittingAgentCount = (data) => {
  
    const groupedData = data.reduce((acc, row) => {
      const [month, year] = row.month.split("/").map(Number); // Parse "mm/yyyy" format
      const adjustedMonth = month - 1; // Convert 1-based month to 0-based index
  
      if (!acc[year]) {
        acc[year] = Array(12).fill(0); // Initialize year with empty months
      }
  
      acc[year][adjustedMonth] += 1; // Increment the count for the corresponding month

  
      return acc;
    }, {});
  
    return groupedData;
  };
  

  const fetchData = async () => {
    try {
      setLoading(true);
  
      const userRole = localStorage.getItem("userRole")?.toUpperCase();
      const agnName = localStorage.getItem("agnName");
  
      if (!agnName) {
        throw new Error("Invalid user role or agnName");
      }
  
      const requests = [];
      let hierarchyData = [];
  
      // Add the ALP data request
      requests.push(
        axios.get(
          `https://ariaslogin-4a95935f6093.herokuapp.com/api/monthly-alp-by-mga?value=${agnName}`
        )
      );
  
      // Add VIPs, Associates, Total Hires, and Submitting Agents requests if the userRole is not AGT
      if (userRole !== "AGT") {
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/vips?column=${userRole === "RGA" ? "mga" : userRole}&value=${agnName}`
          ),
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/associates?column=${userRole === "RGA" ? "mga" : userRole}&value=${agnName}`
          ),
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/total-hires?value=${agnName}`
          ),
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/subagent-alp?value=${agnName}`
          )
        );
      }
  
      // Fetch hierarchy data for MGA or RGA roles
      if ( userRole === "RGA") {
        const hierarchyResponse = await axios.get(
          `https://ariaslogin-4a95935f6093.herokuapp.com/api/get-rgas-hierarchy/${encodeURIComponent(
            agnName
          )}`
        );
        const hierarchyData = hierarchyResponse.data?.data || [];
        setHierarchyData(hierarchyData);
        filterHierarchyData(hierarchyData, selectedView); // Apply initial filtering for default view
        console.log('filteredHierarchyData', filteredHierarchyData);
      }
  
      const responses = await Promise.all(requests);
  
      // Process ALP data
      setAlpData(processAlpData(responses[0]?.data?.data || [], userRole));
  
      // Process VIPs, Associates, Total Hires, and Submitting Agents if applicable
      if (userRole !== "AGT") {
        setVipsData(responses[1]?.data?.data || []);
        setAssociatesData(responses[2]?.data?.data || []);
  
        // Process Total Hires
        const hiresGrouped = groupByMonthAndYear(
          responses[3]?.data?.data || [],
          "MORE_Date"
        );
        setHiresData(hiresGrouped);
  
        // Process Submitting Agent Data
        const subAgentGrouped = processSubmittingAgentCount(
          responses[4]?.data?.data || []
        );
        setSubAgentData(subAgentGrouped);
      }
  
      // Store hierarchy data
      if (hierarchyData.length > 0) {
        setHierarchyData(hierarchyData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };
  
  const filterHierarchyData = (data, view) => {
    if (view === "a") {
      const logs = [];
      const yearsInUse = [currentYear, lastYear];
      const filtersByYear = {};
  
      // Initialize filters for each year in use
      yearsInUse.forEach((year) => {
        filtersByYear[year] = [];
      });
  
      const filteredData = data.filter((entry) => {
        const entryYear = new Date(entry.start).getFullYear();
        const isWithinCurrentYears = entryYear === currentYear || entryYear === lastYear;
        const isLagnnameMatch = entry.lagnname === agnName;
        const isRgaMatchAndRecent = entry.rga === agnName && isWithinCurrentYears;
  
        if (isLagnnameMatch) {
          filtersByYear[entryYear].push({
            reason: "lagnname matches agnName",
            entry,
          });
          logs.push(`Included: ${entry.lagnname} (Reason: lagnname matches agnName)`);
          return true;
        }
  
        if (isRgaMatchAndRecent) {
          filtersByYear[entryYear].push({
            reason: "rga matches agnName and start date is within the current years",
            entry,
          });
          logs.push(
            `Included: ${entry.lagnname} (Reason: rga matches agnName and start date ${entry.start} is within the current years)`
          );
          return true;
        }
  
        logs.push(`Excluded: ${entry.lagnname}`);
        return false;
      });
  
      console.log("Filter Logs for View A:", logs);
      setFilteredHierarchyData(filteredData);
  
      return { yearsInUse, filtersByYear };
    } else {
      setFilteredHierarchyData(data);
    }
  };
  
  
  const handleViewChange = (view) => {
    setSelectedView(view);
    filterHierarchyData(hierarchyData, view); // Apply filtering based on the selected view
  };

  const handleCellClick = (tableName, dataItem, month, year) => {
    if (dataItem) {
      setSelectedTableDetails((prev) => ({
        ...prev,
        [tableName]: { data: dataItem, month, year }, // Store data and additional details
      }));
      
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);
  if (loading) return <div>Loading data...</div>;
  if (error) return <div>Error: {error}</div>;

  const renderSummedTable = (title, data, dateField, isGrouped = false, isCurrency = true) => {
    const currentDetails = selectedTableDetails[title];
  
    if (currentDetails) {
      const { data: detailData, month: monthInfo, year } = currentDetails;
  
      if (title === "VIPs") {
        // Existing VIPs detailed view
        const groupedData = (Array.isArray(detailData) ? detailData : [detailData]).reduce(
          (acc, item) => {
            const name = item.lagnname || "Unknown";
            if (!acc[name]) acc[name] = [];
            acc[name].push(item);
            return acc;
          },
          {}
        );
  
        return (
          <section>
            <h5>{`${title} > ${monthInfo.label} '${String(year).slice(-2)}`}</h5>
            <button
              className="insured-button"
              onClick={() =>
                setSelectedTableDetails((prev) => {
                  const updated = { ...prev };
                  delete updated[title];
                  return updated;
                })
              }
            >
              Back
            </button>
            <div className="details-view">
              {Object.entries(groupedData).map(([lagnname, entries], index) => (
                <table className="hierarchyTable" key={index}>
                  <thead>
                    <tr>
                      <th colSpan={2}>{formatName(lagnname) || "N/A"}</th>
                      <th colSpan={2}>Hierarchy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <th>Code Date</th>
                          <td>{formatDate(entry?.softcode_date) || "N/A"}</td>
                          <th style={{ backgroundColor: "#68B675" }}>MGA</th>
                          <td>{formatName(entry?.mga) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Gross ALP</th>
                          <td>{formatCurrency(entry?.gs) || "N/A"}</td>
                          <th style={{ backgroundColor: "#ED722F" }}>GA</th>
                          <td>{formatName(entry?.ga) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Count</th>
                          <td>{entry?.count || "N/A"}</td>
                          <th style={{ backgroundColor: "#B25271" }}>SA</th>
                          <td>{formatName(entry?.sa) || "N/A"}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </section>
        );
      }
  
      if (title === "Codes") {
        // New Codes detailed view
        const groupedData = (Array.isArray(detailData) ? detailData : [detailData]).reduce(
          (acc, item) => {
            const name = item.LagnName || "Unknown";
            if (!acc[name]) acc[name] = [];
            acc[name].push(item);
            return acc;
          },
          {}
        );
  
        return (
          <section>
            <h5>{`${title} > ${monthInfo.label} '${String(year).slice(-2)}`}</h5>
            <button
              className="insured-button"
              onClick={() =>
                setSelectedTableDetails((prev) => {
                  const updated = { ...prev };
                  delete updated[title];
                  return updated;
                })
              }
            >
              Back
            </button>
            <div className="details-view">
              {Object.entries(groupedData).map(([lagnName, entries], index) => (
                <table className="hierarchyTable" key={index}>
                  <thead>
                    <tr>
                      <th colSpan={2}>{formatName(lagnName)}</th>
                      <th colSpan={2}>Hierarchy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <th>Prod Date</th>
                          <td>{formatDate(entry?.PRODDATE) || "N/A"}</td>
                          <th style={{ backgroundColor: "#68B675" }}>MGA</th>
                          <td>{formatName(entry?.MGA) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th></th>
                          <td></td>
                          <th style={{ backgroundColor: "#ED722F" }}>GA</th>
                          <td>{formatName(entry?.GA) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th></th>
                          <td></td>
                          <th style={{ backgroundColor: "#B25271" }}>SA</th>
                          <td>{formatName(entry?.SA) || "N/A"}</td>
                        </tr>

                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </section>
        );
      }
    }
  
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    const monthIndexToDataIndex = (index) => {
      // We'll return an object with shape { isMonth: boolean, monthIndex?: number, label: string }
      switch (index) {
        case 0: // Jan
          return { isMonth: true, monthIndex: 0, label: "Jan" };
        case 1: // Feb
          return { isMonth: true, monthIndex: 1, label: "Feb" };
        case 2: // Mar
          return { isMonth: true, monthIndex: 2, label: "Mar" };
        case 3: // Q1 column
          return { isMonth: false, label: "Q1" };
        case 4: // Apr
          return { isMonth: true, monthIndex: 3, label: "Apr" };
        case 5: // May
          return { isMonth: true, monthIndex: 4, label: "May" };
        case 6: // Jun
          return { isMonth: true, monthIndex: 5, label: "Jun" };
        case 7: // Q2
          return { isMonth: false, label: "Q2" };
        case 8: // Jul
          return { isMonth: true, monthIndex: 6, label: "Jul" };
        case 9: // Aug
          return { isMonth: true, monthIndex: 7, label: "Aug" };
        case 10: // Sep
          return { isMonth: true, monthIndex: 8, label: "Sep" };
        case 11: // Q3
          return { isMonth: false, label: "Q3" };
        case 12: // Oct
          return { isMonth: true, monthIndex: 9, label: "Oct" };
        case 13: // Nov
          return { isMonth: true, monthIndex: 10, label: "Nov" };
        case 14: // Dec
          return { isMonth: true, monthIndex: 11, label: "Dec" };
        case 15: // Q4
          return { isMonth: false, label: "Q4" };
        case 16: // YTD
          return { isMonth: false, label: "YTD" };
        default:
          return { isMonth: false, label: "" };
      }
    };
  
    const handleCellClickWrapper = (dataItem, monthInfo, year) => {
      // If it's a quarter or YTD (i.e. !isMonth), skip
      if (!monthInfo.isMonth) return;
      handleCellClick(title, dataItem, monthInfo, year);
    };
  
    let currentYearData = [];
    let previousYearData = [];
  
    if (isGrouped) {
      currentYearData = data[currentYear] || Array(12).fill(0);
      previousYearData = data[previousYear] || Array(12).fill(0);
    } else {
      const groupedData = groupByMonthAndYear(data, dateField);
      currentYearData = groupedData[currentYear] || Array(12).fill(0);
      previousYearData = groupedData[previousYear] || Array(12).fill(0);
    }
  
    const currentYearWithQuarters = calculateQuarterSums(currentYearData);
    const previousYearWithQuarters = calculateQuarterSums(previousYearData);
  
    const growthData = currentYearWithQuarters.map((value, index) => {
      const previousValue = previousYearWithQuarters[index];
      const growth = value - previousValue;
      return {
        value: growth,
        className: growth > 0 ? "growth-positive" : growth < 0 ? "growth-negative" : "",
      };
    });
  
    return (
      <section>
        <h5>{title}</h5>
        <div className="recruiting-table-container">
          <table className="scorecard-table">
            <thead>
              <tr>
                <th>Year</th>
                {months.map((month, index) => (
                  <th
                    key={index}
                    className={
                      index === 3 || index === 7 || index === 11 || index === 15
                        ? "quarter-column"
                        : index === 16
                        ? "year-total-column"
                        : ""
                    }
                  >
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
            <tr>
  <td>{previousYear}</td>
  {previousYearWithQuarters.map((value, index) => {
    const monthInfo = monthIndexToDataIndex(index); // Get month info object
    const isQuarterCell = !monthInfo.isMonth; // Check if it's a quarter cell
// Ensure data is always an array
const validData = Array.isArray(data) ? data : [];

// Determine if data exists for the current cell
const dataItem =
  monthInfo.isMonth
    ? validData.filter((item) => {
        const itemDate =
          title === "VIPs" ? new Date(item.vip_month) :
          title === "Codes" ? new Date(item.PRODDATE) :
          null; // Handle cases where neither applies

        return (
          itemDate &&
          itemDate.getMonth() === monthInfo.monthIndex &&
          itemDate.getFullYear() === previousYear // Dynamically match year
        );
      })
    : null;

// Check if the cell is clickable
const isClickable = dataItem && dataItem.length > 0;


    return (
      <td
        key={index}
        className={` 
          ${isQuarterCell ? "quarter-column" : ""}
          ${index === 16 ? "year-total-column" : ""}
          ${isClickable ? "has-data" : ""}
        `}
        title={isClickable ? "Details available" : ""}
        onClick={isClickable ? () => handleCellClickWrapper(dataItem, monthInfo, previousYear) : null}
      >
        {isCurrency ? currencyFormatter.format(value) : value}
      </td>
    );
  })}
</tr>
              <tr>
  <td>{currentYear}</td>
  {currentYearWithQuarters.map((value, index) => {
    const monthInfo = monthIndexToDataIndex(index); // Get month info object
    const isQuarterCell = !monthInfo.isMonth; // Check if it's a quarter cell

// Ensure data is always an array
const validData = Array.isArray(data) ? data : [];

// Determine if data exists for the current cell
const dataItem =
  monthInfo.isMonth
    ? validData.filter((item) => {
        const itemDate =
          title === "VIPs" ? new Date(item.vip_month) :
          title === "Codes" ? new Date(item.PRODDATE) :
          null; // Handle cases where neither applies

        return (
          itemDate &&
          itemDate.getMonth() === monthInfo.monthIndex &&
          itemDate.getFullYear() === currentYear // Dynamically match year
        );
      })
    : null;

// Check if the cell is clickable
const isClickable = dataItem && dataItem.length > 0;



    return (
      <td
        key={index}
        className={` 
          ${isQuarterCell ? "quarter-column" : ""}
          ${index === 16 ? "year-total-column" : ""}
          ${isClickable ? "has-data" : ""}
        `}
        title={isClickable ? "Details available" : ""}
        onClick={isClickable ? () => handleCellClickWrapper(dataItem, monthInfo, currentYear) : null}
      >
        {isCurrency ? currencyFormatter.format(value) : value}
      </td>
    );
  })}
</tr>

              <tr className="growth-row">
                <td>Growth</td>
                {growthData.map(({ value, className }, index) => (
                  <td key={index} className={className}>
                    {isCurrency ? currencyFormatter.format(value) : value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </section>
    );
  };
  
  
  const userRole = localStorage.getItem("userRole")?.toUpperCase();
  return (
    <div className="app-container">
      <div className="scorecard">
      <div className="year-navigation">
  <button onClick={() => navigateYears("back")}>Previous Year</button>
  <span>
    Viewing Data for: {lastYear} & {currentYear}
  </span>
  <button
    onClick={() => navigateYears("forward")}
    disabled={currentYear === new Date().getFullYear()} // Prevent going beyond the current year
  >
    Next Year
  </button>
</div>

        <h1></h1>
        {userRole === "RGA" && (
  <div className="view-toggle">
    <button
      className={selectedView === "a" ? "active" : ""}
      onClick={() => handleViewChange("a")}
    >
      View A
    </button>
    <button
      className={selectedView === "b" ? "active" : ""}
      onClick={() => handleViewChange("b")}
    >
      View B
    </button>
  </div>
)}
          <>
            {userRole !== "AGT" && renderSummedTable("VIPs", vipsData, "vip_month", false, false)}
            {userRole !== "AGT" && renderSummedTable("Codes", associatesData, "PRODDATE", false, false)}
            {renderSummedTable("ALP", alpData, null, true, true)}
            {userRole !== "AGT" &&
              renderSummedTable("Hires", hiresData, "MORE_Date", true, false)}
            {userRole !== "AGT" &&
              renderSummedTable(
                "Submitting Agent Count",
                subAgentData,
                null,
                true,
                false
              )}
          </>
      
      </div>
    </div>
  );
  
  
  
};

export default Scorecard;
