import React, { useState, useEffect } from "react";
import axios from "axios";
import "./More.css";
const AmoreForm = () => {
  const [mgaData, setMgaData] = useState({});
  const [error, setError] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0); // Tracks the week offset
  const [dateRange, setDateRange] = useState(""); // To display "Saturday - Friday"
  const [showNoDataBar, setShowNoDataBar] = useState(false);
  const userRole = localStorage.getItem("userRole");
  const [isCollapsed, setIsCollapsed] = useState(true); // Track if the component is collapsed
  const [countdown, setCountdown] = useState("");

const toggleCollapse = () => {
  setIsCollapsed((prev) => !prev);
};

const calculateCountdown = (weekOffset = 0) => {
    const now = new Date();
  
    // Convert current time to EST
    const currentEST = new Date(
      now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
  
    // Calculate the Friday of the selected week
    const startOfWeek = new Date(currentEST);
    startOfWeek.setDate(
      startOfWeek.getDate() + weekOffset * 7 - startOfWeek.getDay() + 5
    ); // Move to the correct Friday
    startOfWeek.setHours(15, 15, 0, 0); // Set deadline time to 3:15 PM EST
  
    // Calculate the difference in milliseconds
    const diff = startOfWeek - currentEST;
  
    if (diff <= 0) {
      setCountdown("Deadline passed");
      return;
    }
  
    // Convert to days, hours, minutes, seconds
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  
    // Dynamically build the countdown string, omitting zero values except seconds
    const countdownArray = [];
    if (days > 0) countdownArray.push(`${days}d`);
    if (hours > 0 || days > 0) countdownArray.push(`${hours}h`);
    if (minutes > 0 || hours > 0 || days > 0) countdownArray.push(`${minutes}m`);
    countdownArray.push(`${seconds}s`); // Always show seconds
  
    setCountdown(countdownArray.join(" "));
  };
  
  useEffect(() => {
    const updateCountdown = () => {
      calculateCountdown(currentWeek);
    };
  
    // Update countdown every second
    const interval = setInterval(updateCountdown, 1000);
  
    // Run the calculation initially
    updateCountdown();
  
    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [currentWeek]);
  

  const [formData, setFormData] = useState({
    MORE_Date: "", // Auto-set to the upcoming Friday midnight
    MGA: "",
    External_Sets: null,
    External_Shows: null,
    Internal_Sets: null,
    Internal_Shows: null,
    Personal_Sets: null,
    Personal_Shows: null,
    Total_Set: null,
    Total_Show: null,
    Group_Invite: null,
    Finals_Set: null,
    Finals_Show: null,
    Non_PR_Hires: null,
    PR_Hires: null,
    Total_Hires: null,
    RGA: "",
    Legacy: "",
    Tree: "",
    Office: "",
  });

  const fetchFormData = async (MGA, MORE_Date) => {
    try {
        // Fetch data for the specific week and all weeks
        const [currentWeekResponse, allDataResponse] = await Promise.all([
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/fetch-more-data/${MGA}/${MORE_Date}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/all-amore-data`),
        ]);

        if (currentWeekResponse.data.success && currentWeekResponse.data.data) {
            const fetchedData = currentWeekResponse.data.data;

            const updatedData = Object.keys(fetchedData).reduce((acc, key) => {
                acc[key] = fetchedData[key] === 0 ? 0 : fetchedData[key];
                return acc;
            }, {});

            setFormData({
                ...updatedData,
                MORE_Date: MORE_Date, // Keep backend format yyyy-mm-dd
            });
            setShowNoDataBar(false); // Hide the no data bar if data is fetched
        } else {
            // Clear the form and show the no data bar
            setFormData((prev) => ({
                ...prev,
                External_Sets: null,
                External_Shows: null,
                Internal_Sets: null,
                Internal_Shows: null,
                Personal_Sets: null,
                Personal_Shows: null,
                Total_Set: null,
                Total_Show: null,
                Group_Invite: null,
                Finals_Set: null,
                Finals_Show: null,
                Non_PR_Hires: null,
                PR_Hires: null,
                Total_Hires: null,
            }));
            setShowNoDataBar(true); // Show the no data bar
        }

        // Calculate the reporting streak from all weeks' data
        if (allDataResponse.data.success && allDataResponse.data.data) {
            calculateReportingStreak(allDataResponse.data.data);
        }
    } catch (err) {
        setError("Failed to fetch data for the selected week.");
        setShowNoDataBar(true); // Show the no data bar on error
    }
};

  const handleNoRecruit = async () => {
    const rga = localStorage.getItem("RGA") || formData.MGA;
    const legacy = localStorage.getItem("Legacy") || formData.MGA;
    const tree = localStorage.getItem("Tree") || formData.MGA;

    const zeroData = {
        External_Sets: 0,
        External_Shows: 0,
        Internal_Sets: 0,
        Internal_Shows: 0,
        Personal_Sets: 0,
        Personal_Shows: 0,
        Total_Set: 0,
        Total_Show: 0,
        Group_Invite: 0,
        Finals_Set: 0,
        Finals_Show: 0,
        Non_PR_Hires: 0,
        PR_Hires: 0,
        Total_Hires: 0,
        RGA: rga,
        Legacy: legacy,
        Tree: tree,
    };

    const isOnTime = currentWeek === 0 && countdown !== "Deadline passed";

    // Update the local formData state
    setFormData((prev) => ({
        ...prev,
        ...zeroData,
    }));

    // Send the data to the backend
    try {
        if (formData.MGA && formData.MORE_Date) {
            await updateData(formData.MGA, formData.MORE_Date, zeroData, isOnTime);
            alert(`Recruiting numbers have been recorded as 0${isOnTime ? ' on time.' : '.'}`);
        } else {
            alert("MGA or recruiting date is missing. Unable to record data.");
        }
    } catch (error) {
        console.error("Error recording recruiting numbers as 0:", error.message);
        alert("Failed to record recruiting numbers as 0. Please try again.");
    }
};



const updateData = async (MGA, MORE_Date, updates, onTime = false) => {
    try {
        const userRole = localStorage.getItem("userRole");
        const RGA = localStorage.getItem("RGA") || MGA;
        const Legacy = localStorage.getItem("Legacy") || MGA;
        const Tree = localStorage.getItem("Tree") || MGA;

        const payload = {
          MGA,
          MORE_Date,
          updates,
          userRole,
          on_time: onTime,
          rga: RGA,
          legacy: Legacy,
          tree: Tree,
      };
      console.log("Payload being sent:", payload);
      
        console.log("Sending updates:", payload);
        const response = await axios.post(
            "https://ariaslogin-4a95935f6093.herokuapp.com/api/update-more-data",
            payload
        );
    } catch (error) {
        console.error("Error updating data:", error.message);
    }
};


const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseInt(value || 0, 10); // Ensure values are integers or 0
    let showAlert = false; // Temporary flag to control alert
  
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        [name]: value,
      };
  
      // Validation for Shows and Sets
      const setsAndShows = [
        { set: "External_Sets", show: "External_Shows" },
        { set: "Internal_Sets", show: "Internal_Shows" },
        { set: "Personal_Sets", show: "Personal_Shows" },
      ];
  
      for (const pair of setsAndShows) {
        if (name === pair.show || name === pair.set) {
          const sets = parseInt(updatedFormData[pair.set] || 0, 10);
          const shows = parseInt(updatedFormData[pair.show] || 0, 10);
  
          if (shows > sets) {
            if (!showAlert) {
              alert(`${pair.show.replace("_", " ")} cannot exceed ${pair.set.replace("_", " ")}`);
              showAlert = true;
            }
            updatedFormData[pair.show] = prev[pair.show] || ""; // Revert to the previous value
          }
        }
      }
  
      // Validation for Hires
      if (name === "PR_Hires" || name === "Total_Hires") {
        const totalHires = parseInt(updatedFormData.Total_Hires || 0, 10);
        const prHires = parseInt(updatedFormData.PR_Hires || 0, 10);
  
        if (prHires > totalHires) {
          if (!showAlert) {
            alert("PR Hires cannot exceed Total Hires");
            showAlert = true;
          }
          updatedFormData.PR_Hires = prev.PR_Hires || ""; // Revert to the previous value
        } else {
          // Calculate Non PR Hires
          updatedFormData.Non_PR_Hires = totalHires - prHires;
        }
      } else {
        // Ensure Non_PR_Hires is recalculated for any unrelated changes
        updatedFormData.Non_PR_Hires =
          parseInt(updatedFormData.Total_Hires || 0, 10) -
          parseInt(updatedFormData.PR_Hires || 0, 10);
      }
  
      return updatedFormData;
    });
  
    // Trigger backend update for every field change, except MGA and MORE_Date
    if (formData.MGA && formData.MORE_Date) {
      const updates = { [name]: value };
  
      // Include recalculated Non PR Hires in backend updates
      if (name === "Total_Hires" || name === "PR_Hires") {
        const totalHires = parseInt(formData.Total_Hires || 0, 10);
        const prHires = parseInt(formData.PR_Hires || 0, 10);
        updates.Non_PR_Hires = totalHires - prHires;
      }
  
      // Determine if the reporting is on time for the current week
      const isOnTime = currentWeek === 0 && countdown !== "Deadline passed";
  
      // Send updates to the backend with `on_time`
      updateData(formData.MGA, formData.MORE_Date, updates, isOnTime);
    } else {
      console.warn("MGA or MORE_Date is missing; skipping update");
    }
  };
  
  const calculateReportingStreak = (allWeeksData) => {
    // Filter by current MGA if needed, or calculate globally
    const filteredData = allWeeksData.filter((week) => week.MGA === formData.MGA);

    // Sort weeks data by date in descending order (latest week first)
    const sortedWeeks = filteredData.sort((a, b) => new Date(b.MORE_Date) - new Date(a.MORE_Date));

    // Count consecutive `on_time: true` weeks
    let streak = 0;
    for (const week of sortedWeeks) {
        if (week.on_time) {
            streak++;
        } else {
            break; // Break the loop on the first non-on-time week
        }
    }

    // Save the streak to the state for display
    setMgaData((prev) => ({ ...prev, streak }));
};

  
  const calculateDateRange = (weekOffset = 0) => {
    const now = new Date();
    const currentDay = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  
    // Get the Saturday of the desired week
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - currentDay - 1 + weekOffset * 7); // Adjust to Saturday and apply offset
  
    // Get the Friday of the desired week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Add 6 days for Friday
  
    // Format for display (mm/dd/yy)
    const formatToMMDDYY = (date) => {
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const yy = String(date.getFullYear()).slice(-2);
      return `${mm}/${dd}/${yy}`;
    };
  
    // Format for backend (yyyy-mm-dd)
    const formatToYYYYMMDD = (date) => {
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const yyyy = date.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };
  
    const formattedRange = `${formatToMMDDYY(startOfWeek)} - ${formatToMMDDYY(endOfWeek)}`;
    const formattedFriday = formatToYYYYMMDD(endOfWeek); // Backend format
  
    return { formattedRange, formattedFriday };
  };
  
  
  

  const updateWeek = (offsetChange) => {
    setCurrentWeek((prev) => prev + offsetChange); // Update week offset
  };

  useEffect(() => {
    const updateDateRange = async () => {
      const { formattedRange, formattedFriday } = calculateDateRange(currentWeek);
      setDateRange(formattedRange); // Display mm/dd/yy-mm/dd/yy
      setFormData((prev) => ({
        ...prev,
        MORE_Date: formattedFriday, // Backend yyyy-mm-dd
      }));
  
      // Fetch data for the current week
      if (formData.MGA) {
        await fetchFormData(formData.MGA, formattedFriday);
      }
    };
  
    // Update the date range whenever `currentWeek` changes
    updateDateRange();
  }, [currentWeek, formData.MGA]);
  

  useEffect(() => {
    const fetchMgaData = async () => {
        try {
            const agnName = localStorage.getItem("agnName");
            const response = await axios.get(
                `https://ariaslogin-4a95935f6093.herokuapp.com/api/get-mga`,
                { params: { agnName } }
            );

            if (response.data.success && response.data.data) {
                const fetchedData = response.data.data;

                // Set MGA constants
                const MGA = fetchedData.lagnname || "";
                const RGA = fetchedData.rga || MGA;
                const Legacy = fetchedData.legacy || MGA;
                const Tree = fetchedData.tree || "";

                setMgaData(fetchedData); // Optional, for UI display or streak calculation

                // Populate the formData with fetched data
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    MGA,
                }));

                // Store constants for later use
                localStorage.setItem("RGA", RGA);
                localStorage.setItem("Legacy", Legacy);
                localStorage.setItem("Tree", Tree);

                console.log("MGA data fetched:", fetchedData);
            } else {
                console.error("Failed to fetch MGA data:", response.data.message);
                setError(response.data.message || "Unexpected error occurred.");
            }
        } catch (err) {
            console.error("Failed to fetch MGA data:", err.message);
            setError(err.message || "Unexpected error occurred.");
        }
    };

    fetchMgaData();
}, []);


  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className="more-form-container">
{showNoDataBar ? (
    <div className="warning-message-container">
  <div className="warning-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="warning-icon-svg"
    >
      <circle cx="12" cy="12" r="12" fill="#ffe4a1" />
      <circle cx="12" cy="12" r="9" fill="#ffffff" />
      <text
        x="12"
        y="16"
        textAnchor="middle"
        fontSize="14"
        fill="#ffb300"
        fontWeight="bold"
      >
        !
      </text>
    </svg>
  </div>
  <div className="warning-message-content">
    <h5>Notice</h5>
    <p>
  {countdown === "Deadline passed"
    ? "The deadline has passed to report recruiting numbers on time this week. You can still report your numbers now for your own records."
    : <>
        You have <strong>{countdown}</strong> to report recruiting numbers on time this week{" "}
        {mgaData.streak > 0
          ? `and keep your ${mgaData.streak} ${mgaData.streak === 1 ? "week" : "weeks"} streak.`
          : "and start a new streak."}
      </>}
</p>

    <div className="more-button-group">
      <button className="more-toggle-collapse-button" onClick={toggleCollapse}>
        {isCollapsed ? "Report MORE Numbers" : "Hide Report MORE"}
      </button>
      <button
        className="no-recruit-notice-button"
        onClick={() => handleNoRecruit()}
      >
        I Didn't Recruit
      </button>
    </div>
  </div>
</div>


) : (
<div className="success-message-container">
  <div className="success-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="success-icon-svg"
    >
      <circle cx="12" cy="12" r="12" fill="#d4edda" />
      <circle cx="12" cy="12" r="9" fill="#ffffff" />
      <text
        x="12"
        y="16"
        textAnchor="middle"
        fontSize="14"
        fill="#28a745"
        fontWeight="bold"
      >
        🔥
      </text>
    </svg>
  </div>
  <div className="success-message-content">
    <h5>
      Keep up the consistency{" "}
      <strong>
        {localStorage.getItem("agnName")
          ?.split(" ")[1]
          ?.toLowerCase()
          ?.replace(/^./, (char) => char.toUpperCase()) || "Agent"}
      </strong>
    </h5>
    {mgaData.streak > 0 && (
      <p>
        Your current reporting streak is{" "}
        <strong>
          {mgaData.streak} {mgaData.streak === 1 ? "week" : "weeks"}
        </strong>
        . Report before 3:15 PM EST on Fridays to maintain your streak.
      </p>
    )}
    <div className="more-button-group">
      <button
        className="success-more-toggle-collapse-button"
        onClick={toggleCollapse}
      >
        {isCollapsed ? "Review MORE" : "Hide MORE"}
      </button>
    </div>
  </div>
</div>



)}



      {!isCollapsed && (
        <>
          <div className="more-date-navigation">
            <button className="more-week-button" onClick={() => updateWeek(-1)}>
              &lt;
            </button>
            <div className="date-range-container">
              <span className="recruiting-week-label">Recruiting Week:</span>
              <select
                className="week-selector"
                value={currentWeek}
                onChange={(e) => setCurrentWeek(parseInt(e.target.value, 10))}
              >
                {[...Array(100)] // Generate enough past weeks
                  .map((_, index) => -index) // Past weeks as negative offsets
                  .concat(0, 1) // Include current and next week
                  .map((offset) => {
                    const { formattedRange } = calculateDateRange(offset);
                    return (
                      <option key={offset} value={offset}>
                        {formattedRange}
                      </option>
                    );
                  })}
              </select>
              {currentWeek === 0 && <span className="week-indicator">This Week</span>}
              {currentWeek === -1 && <span className="week-indicator">Last Week</span>}
              {currentWeek === 1 && <span className="week-indicator">Next Week</span>}
            </div>
            <button className="more-week-button" onClick={() => updateWeek(1)}>
              &gt;
            </button>
          </div>
  

  
          <div className="vertical-table">
            <table className="morebonus-table">
              <tbody>
                {[
                  { category: "Vendor Data", rows: ["External Sets", "External Shows"], color: "#00548c" },
                  { category: "Resume Data", rows: ["Internal Sets", "Internal Shows"], color: "#ED722F" },
                  { category: "Personal Data", rows: ["Personal Sets", "Personal Shows"], color: "#B25271" },
                  { category: "Overview Data", rows: ["Total Set", "Total Show", "Group Invite"], color: "#bbbbbb" },
                  { category: "Finals Data", rows: ["Finals Set", "Finals Show"], color: "#319b43bb" },
                  { category: "Hires Data", rows: ["Total Hires", "PR Hires", "Non PR Hires"], color: "#00548c" },
                ].map(({ category, rows, color }) =>
                  rows.map((row, index) => (
                    <tr key={row}>
                      {index === 0 && (
                        <td
                          style={{
                            backgroundColor: color,
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          rowSpan={rows.length}
                        >
                          {category}
                        </td>
                      )}
                      <td>{row}</td>
                      <td>
                        <input
                          className="more-form-input"
                          type={
                            row.includes("Sets") ||
                            row.includes("Shows") ||
                            row.includes("Hires") ||
                            row.includes("Total")
                              ? "number"
                              : "text"
                          }
                          name={row.replace(/\s/g, "_")}
                          value={
                            formData[row.replace(/\s/g, "_")] === null
                              ? ""
                              : formData[row.replace(/\s/g, "_")]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
  
          <div className="horizontal-table">
            <table className="morebonus-table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#00548c" }} colSpan="2" className="more-table-header">Vendor Data</th>
                  <th style={{ backgroundColor: "#ED722F" }} colSpan="2" className="more-table-header">Resume Data</th>
                  <th style={{ backgroundColor: "#B25271" }} colSpan="2" className="more-table-header">Personal Data</th>
                  <th style={{ backgroundColor: "#bbbbbb" }} colSpan="3" className="more-table-header">Overview Data</th>
                  <th style={{ backgroundColor: "#319b43bb" }} colSpan="2" className="more-table-header">Finals Data</th>
                  <th style={{ backgroundColor: "#00548c" }} colSpan="3" className="more-table-header">Hires Data</th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">External Sets</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">External Shows</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Internal Sets</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Internal Shows</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Personal Sets</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Personal Shows</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Total Sets</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Total Shows</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Group Invite</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Finals Set</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Finals Show</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Total Hires</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">PR Hires</th>
                  <th style={{ backgroundColor: "#319b43bb" }} className="more-table-header">Non PR Hires</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {[
                    "External_Sets",
                    "External_Shows",
                    "Internal_Sets",
                    "Internal_Shows",
                    "Personal_Sets",
                    "Personal_Shows",
                    "Total_Set",
                    "Total_Show",
                    "Group_Invite",
                    "Finals_Set",
                    "Finals_Show",
                    "Total_Hires",
                    "PR_Hires",
                    "Non_PR_Hires",
                  ].map((name) => (
                    <td key={name}>
                      <input
                        className="more-form-input"
                        type={
                          name.includes("Sets") ||
                          name.includes("Shows") ||
                          name.includes("Hires") ||
                          name.includes("Total")
                            ? "number"
                            : "text"
                        }
                        name={name}
                        value={formData[name] === null ? "" : formData[name]}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
  
};

export default AmoreForm;