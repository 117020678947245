import React, { useEffect, useState } from 'react';
import MyTrophyCase from './MyTrophyCase';
import HierarchyTable from './HierarchyTable';
import ContributionsTable from './Contributions';
import AccountCreationForm from '../Admin/CreateAccount'
import { Modal, Button, Form } from 'react-bootstrap';
import './Account.css';
import StatCard from '../utils/StatCard';
import ManagerStats from './ManagerStats';
import ChangePassword from '../Auth/ChangePassword';

const Account = () => {
  const [userData, setUserData] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [tenure, setTenure] = useState('');
  const [anniversaryMessage, setAnniversaryMessage] = useState('');
  const [reportingStreak, setReportingStreak] = useState(0); // Add state for reporting streak
  const [allTimeDaysReported, setAllTimeDaysReported] = useState(0); // Add state for all-time days reported
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [editMode, setEditMode] = useState(false); // To toggle edit mode
  const [email, setEmail] = useState(''); // For updating email
  const [phone, setPhone] = useState(''); // For updating phone
  const [initialEmail, setInitialEmail] = useState(''); // Store initial email
  const [initialPhone, setInitialPhone] = useState(''); // Store initial phone
  const [formattedAgnName, setFormattedAgnName] = useState(''); // Add state for formatted agnName
  const userRole = localStorage.getItem('userRole');
  const agnName = localStorage.getItem('agnName');
  const [showChangePassword, setShowChangePassword] = useState(false);

  
  useEffect(() => {
    const fetchDailyActivity = async () => {
      try {
        const agnName = localStorage.getItem('agnName');
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          const agentActivities = data.data
            .filter(item => item.agent === agnName)
            .map(item => new Date(item.reportDate).toISOString().slice(0, 10));
          calculateReportingStreak(agentActivities);
          calculateAllTimeDaysReported(agentActivities);
        } else {
          console.error('Failed to load daily activity data');
        }
      } catch (error) {
        console.error('Error fetching daily activity:', error);
      }
    };

    fetchDailyActivity();
  }, []);

  const calculateReportingStreak = (activityDates) => {
    const sortedDates = activityDates.sort((a, b) => new Date(a) - new Date(b));
    let streak = 0;
    let currentStreak = 0;
    const today = new Date().toISOString().slice(0, 10);

    for (let i = sortedDates.length - 1; i >= 0; i--) {
      const date = sortedDates[i];
      const previousDate = sortedDates[i - 1] ? new Date(sortedDates[i - 1]) : null;
      const currentDate = new Date(date);

      // Stop processing if we encounter a future date
      if (currentDate.toISOString().slice(0, 10) > today) {
        continue;
      }

      if (previousDate) {
        previousDate.setDate(previousDate.getDate() + 1);
        if (currentDate.toISOString().slice(0, 10) === previousDate.toISOString().slice(0, 10)) {
          currentStreak++;
        } else if (currentDate.toISOString().slice(0, 10) === today) {
          streak = Math.max(streak, currentStreak + 1);
          currentStreak = 0;
        } else {
          streak = Math.max(streak, currentStreak);
          currentStreak = 0;
        }
      } else if (currentDate.toISOString().slice(0, 10) === today) {
        streak = Math.max(streak, currentStreak + 1);
      } else {
        streak = Math.max(streak, currentStreak);
      }
    }

    setReportingStreak(streak);
  };

  const calculateAllTimeDaysReported = (activityDates) => {
    const today = new Date().toISOString().slice(0, 10);
    const pastDates = activityDates.filter(date => date <= today);
    const uniqueDates = new Set(pastDates);
    setAllTimeDaysReported(uniqueDates.size);
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      setUserData(userInfo);
      setProfilePicture(userInfo.profpic || 'https://via.placeholder.com/150');
      setEmail(userInfo.email);
      setPhone(userInfo.phone);
      setInitialEmail(userInfo.email);
      setInitialPhone(userInfo.phone);
      calculateTenure(userInfo.esid);
    }
  }, []);
  
  useEffect(() => {
    // Format and set agnName
    const agnName = localStorage.getItem('agnName');
    if (agnName) {
      const nameParts = agnName.split(' ');
      if (nameParts.length >= 3) {
        // Assuming the format is "Last First Middle Suffix"
        const formattedName = `${nameParts[1]} ${nameParts[2]} ${nameParts[0]} ${nameParts.slice(3).join(' ')}`.trim();
        setFormattedAgnName(formattedName);
      } else {
        setFormattedAgnName(agnName); // Fallback to original if format is unexpected
      }
    }
  }, []);
  
  const formatName = (name) => {
    if (!name) return '';
  
    const nameParts = name.split(' ');
  
    // If name has two parts: Last First
    if (nameParts.length === 2) {
      return `${nameParts[1]} ${nameParts[0]}`;
    }
  
    // If name has three parts: Last First Middle
    if (nameParts.length === 3) {
      return `${nameParts[1]} ${nameParts[2]} ${nameParts[0]}`;
    }
  
    // If name has four or more parts: Last First Middle Suffix
    if (nameParts.length >= 4) {
      return `${nameParts[1]} ${nameParts[2]} ${nameParts[0]} ${nameParts.slice(3).join(' ')}`;
    }
  
    // Fallback for unexpected format
    return name;
  };
  
  const calculateTenure = (esid) => {
    const startDate = new Date(esid);
    const now = new Date();

    // Reset time components to 0 for comparison
    startDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const diffTime = now - startDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    const weeks = Math.floor((diffDays % 30) / 7);
    const days = diffDays % 7;

    let tenureString = '';

    if (years > 0) {
      tenureString += `${years} year${years > 1 ? 's' : ''}`;
    }

    if (months > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${months} month${months > 1 ? 's' : ''}`;
    }

    if (weeks > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${weeks} week${weeks > 1 ? 's' : ''}`;
    }

    if (days > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${days} day${days > 1 ? 's' : ''}`;
    }

    setTenure(tenureString);

    // Check for anniversary
    const startMonthDay = startDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
    const nowMonthDay = now.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });

    if (startMonthDay === nowMonthDay) {
      setAnniversaryMessage(`Happy ${years} year${years > 1 ? 's' : ''} Anniversary!`);
    } else {
      setAnniversaryMessage('');
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
  
    if (!userId) {
      console.error('No userId found in localStorage');
      return;
    }
  
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);
      formData.append('userId', userId);
  
      try {
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/upload-profile-pic', {
          method: 'POST',
          body: formData,
        });
        const result = await response.json();
  
        if (result.success) {
          setProfilePicture(result.filePath);
          // Update userInfo in localStorage
          const updatedUserInfo = { ...userData, profpic: result.filePath };
          setUserData(updatedUserInfo);
          localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        } else {
          console.error('Failed to upload profile picture:', result.message);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  };
  

  const handleRemovePicture = async () => {
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
  
    if (!userId) {
      console.error('No userId found in localStorage');
      return;
    }
  
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/remove-profile-picture', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      const result = await response.json();
  
      if (result.success) {
        setProfilePicture('https://via.placeholder.com/150');
        // Update userInfo in localStorage
        const updatedUserInfo = { ...userData, profpic: '' };
        setUserData(updatedUserInfo);
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      } else {
        console.error('Failed to remove profile picture:', result.message);
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
    }
  };
  
  const handleSaveContactInfo = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('No userId found in localStorage');
      return;
    }
  
    const updates = {};
    if (email !== initialEmail) updates.email = email;
    if (phone !== initialPhone) updates.phone = phone;
  
    if (Object.keys(updates).length === 0) {
      alert('No changes detected.');
      setEditMode(false);
      return;
    }
  
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/update-contact', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, ...updates }),
      });
      const result = await response.json();
  
      if (result.success) {
        // Update the local state and localStorage
        const updatedUserInfo = { ...userData, ...updates };
        setUserData(updatedUserInfo);
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        setEditMode(false);
        alert('Contact information updated successfully!');
      } else {
        console.error('Failed to update contact information:', result.message);
      }
    } catch (error) {
      console.error('Error updating contact information:', error);
    }
  };
  
  useEffect(() => {
    const fetchHierarchyData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const agnName = localStorage.getItem('agnName');
        if (!userId || !agnName) return;
  
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });
  
        const result = await response.json();
  
        if (result.success && Array.isArray(result.data)) {
          // Find the row where lagnname matches agnName
          const matchingRow = result.data.find((row) => row.lagnname === agnName);
  
          if (matchingRow) {
            setUserData((prevData) => ({
              ...prevData,
              sa: matchingRow.sa || 'N/A',
              ga: matchingRow.ga || 'N/A',
              mga: matchingRow.mga || 'N/A',
              rga: matchingRow.rga || 'N/A',
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching hierarchy data:', error);
      }
    };
  
    fetchHierarchyData();
  }, []);
  
  
  useEffect(() => {
    const fetchTreeData = async () => {
      if (!['MGA', 'RGA'].includes(userRole)) return;
  
      try {
        const agnName = localStorage.getItem('agnName');
        if (!agnName) return;
  
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/get-mga`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ agnName }),
        });
        const data = await response.json();
  
        if (data.success) {
          setUserData((prevData) => ({
            ...prevData,
            tree: data.tree || 'N/A',
          }));
        }
      } catch (error) {
        console.error('Error fetching tree data:', error);
      }
    };
  
    fetchTreeData();
  }, [userRole]);
  
  
  const handleEditMode = () => setEditMode(true);
  const handleCancelEdit = () => setEditMode(false);
  
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (!userData) {
    return <div>Loading...</div>;
  }


  return (
    <>
        {showChangePassword ? (
          <ChangePassword onBackToAccount={() => setShowChangePassword(false)} />
    ) : (
      <div className='app-container'>
      <div className='profile-container'>
        <div className='profile-left'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="image-container">
          <img
            src={profilePicture}
            alt="Profile"
            className="profile-image"
          />
          <div className="dropdown-menu">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('fileInput').click();
              }}
            >
              Upload
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRemovePicture();
              }}
            >
              Remove
            </a>
          </div>
        </div>
          </div>


</div>

  <div className='profile-right'>
        <div className="user-info">
  {/* Add formatted agnName below the profile picture */}
  <div className='name-role-container'>
  {agnName && (
    <p className="formatted-agn-name">{formatName(agnName)}</p>
  )}  {userRole && (
  <span
    className="user-role-badge"
    style={{
      backgroundColor:
        userRole === 'AGT'
          ? 'lightgrey'
          : userRole === 'SA'
          ? 'rgb(178, 82, 113)'
          : userRole === 'GA'
          ? 'rgb(237, 114, 47)'
          : userRole === 'MGA'
          ? 'rgb(104, 182, 117)'
          : userRole === 'RGA'
          ? '#00558c'
          : 'transparent',
      border: `2px solid ${
        userRole === 'AGT'
          ? 'grey'
          : userRole === 'SA'
          ? 'rgb(138, 62, 93)' // Slightly darker
          : userRole === 'GA'
          ? 'rgb(197, 94, 37)' // Slightly darker
          : userRole === 'MGA'
          ? 'rgb(84, 152, 97)' // Slightly darker
          : userRole === 'RGA'
          ? '#004372' // Slightly darker
          : 'transparent'
      }`,
    }}
  >
    {userRole}
  </span>
)}

</div>

  {anniversaryMessage && <p className="anniversary-message">{anniversaryMessage}</p>}
  <p>Started {new Date(userData.esid).toLocaleDateString('en-US')}</p>
  <p style={{fontSize: '14px'}}><strong>Tenure: {tenure}</strong></p>
  </div>

        {/* Conditional rendering for contact info */}
        <div className="contact-info-container">
  <Form.Group className="contact-info-group">
    <Form.Label>Email</Form.Label>
    <Form.Control
      type="email"
      value={email}
      disabled={!editMode} // Disable input field based on editMode
      onFocus={(e) => e.target.select()} // Select text on focus
      onChange={(e) => setEmail(e.target.value)}
    />
  </Form.Group>
  <Form.Group className="contact-info-group">
    <Form.Label>Phone</Form.Label>
    <Form.Control
      type="text"
      value={phone}
      disabled={!editMode} // Disable input field based on editMode
      onFocus={(e) => e.target.select()} // Select text on focus
      onChange={(e) => setPhone(e.target.value)}
    />
  </Form.Group>
</div>
<div>
  {editMode ? (
    <div>
      <button  onClick={handleSaveContactInfo} className="insured-button">
        Save
      </button>
      <button onClick={handleCancelEdit} className="insured-button">
        Cancel
      </button>
    </div>
  ) : (
    <button onClick={handleEditMode} className="insured-button">
      Edit Contact Info
    </button>
  )}

<button
              onClick={() => setShowChangePassword(true)}
              className="insured-button"
            >
              Change Password
            </button>
</div>
</div>
</div>
<div className="uplines-hierarchy-container">
<div className='uplines-container'>
  <h5>Uplines</h5>
  <p style={{ fontSize: '10px', color: 'grey' }}>
  It is recommended to follow the{' '}
  <span
    style={{
      fontWeight: 'bold',
      cursor: 'help',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    }}
    title="The chain of command means contacting the first upline in your hierarchy with a question, from SA-RGA. If that upline does not have an answer, you and that upline can bring the question to the next upline. This ensures keeping lines of communication clear for all levels of the hierarchy."
  >
    chain of command
  </span>
  .
</p>
  <table className="manager-stats-table">
  <tbody>
    {userRole === 'AGT' && (
      <>
        <tr>
          <th style={{backgroundColor: 'rgb(178, 82, 113)'}}>SA</th>
          <td>{userData.sa ? formatName(userData.sa) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: 'rgb(237, 114, 47)'}}>GA</th>
          <td>{userData.ga ? formatName(userData.ga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: 'rgb(104, 182, 117)'}}>MGA</th>
          <td>{userData.mga ? formatName(userData.mga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: '#00558c'}}>RGA</th>
          <td>{userData.rga ? formatName(userData.rga) : 'N/A'}</td>
        </tr>
      </>
    )}
    {userRole === 'SA' && (
      <>
        <tr>
          <th style={{backgroundColor: 'rgb(237, 114, 47)'}}>GA</th>
          <td>{userData.ga ? formatName(userData.ga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: 'rgb(104, 182, 117)'}}>MGA</th>
          <td>{userData.mga ? formatName(userData.mga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: '#00558c'}}>RGA</th>
          <td>{userData.rga ? formatName(userData.rga) : 'N/A'}</td>
        </tr>
      </>
    )}
    {userRole === 'GA' && (
      <>
        <tr>
          <th style={{backgroundColor: 'rgb(104, 182, 117)'}}>MGA</th>
          <td>{userData.mga ? formatName(userData.mga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: '#00558c'}}>RGA</th>
          <td>{userData.rga ? formatName(userData.rga) : 'N/A'}</td>
        </tr>
      </>
    )}
    {userRole === 'MGA' && (
      <>
        <tr>
          <th style={{backgroundColor: '#00558c'}}>RGA</th>
          <td>{userData.rga ? formatName(userData.rga) : 'N/A'}</td>
        </tr>
        <tr>
          <th style={{backgroundColor: 'rgb(178, 82, 113)'}}>Tree</th>
          <td>{userData.tree || 'N/A'}</td>
        </tr>
      </>
    )}
    {userRole === 'RGA' && (
      <tr>
        <th style={{backgroundColor: 'rgb(178, 82, 113)'}}>Tree</th>
        <td>{userData.tree || 'N/A'}</td>
      </tr>
    )}
  </tbody>
</table>

      </div>  
      <div className="hierarchy-table-container">
        <h5>Downlines</h5>
        <p style={{ fontSize: '10px', color: 'grey' }}>
        Agents set to inactive will move to the bottom of the table and will not be seen on your reports.
      </p>
      <HierarchyTable />
      </div>
      </div>
      {['GA', 'MGA', 'RGA'].includes(userRole) && (
        <button onClick={handleOpenModal} className="dashboard-button">
          Create Trainee Account
        </button>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
        <AccountCreationForm />
        </Modal.Body>
      </Modal>       
       <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
    </div>
    )}

    </>
  );
};

export default Account;
