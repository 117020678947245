import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainButtons.css'; // Optional for custom styles if needed.

const MainButtons = () => {
    const popupWindows = React.useRef([]);

    // Global variable to store the currently opened presentation window
    let presentationWindow = null;

    // Updated async openPres function
    const openPres = async (presFile) => {
        // Check if a presentation window is already open and not closed
        if (presentationWindow && !presentationWindow.closed) {
            presentationWindow.focus();
            return;
        }

        // Determine available screen dimensions
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;

        // Check for ultrawide (32:9 aspect ratio or similar)
        const isUltrawide = screenWidth / screenHeight > 2;

        // Calculate dimensions
        let width = Math.round(screenWidth * 0.71);
        let height = Math.round(width * (9 / 16));

        if (height > screenHeight) {
            height = screenHeight;
            width = Math.round(height * (16 / 9));
        }

        let left, top;

        if (isUltrawide) {
            // Calculate a centered virtual 16:9 screen in the middle of the 32:9 monitor
            const virtualWidth = screenHeight * (16 / 9); // Virtual 16:9 width based on screen height
            const virtualLeft = (screenWidth - virtualWidth) / 2; // Centered 16:9 viewport

            // Align to the left side of the virtual 16:9 screen
            left = Math.round(virtualLeft); // Align left of the virtual viewport
            width = Math.round(virtualWidth * 0.71); // 70% of virtual 16:9 width
            height = Math.round(width * (9 / 16)); // Maintain 16:9 aspect ratio
        } else {
            // Standard positioning for non-ultrawide screens
            left = window.screen.availLeft !== undefined ? window.screen.availLeft : 0;
        }

        top = screenHeight - height; // Align to bottom

        // Open the presentation window
        presentationWindow = window.open(
            presFile,
            '_blank',
            `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
        );

        if (presentationWindow) {
            try {
                presentationWindow.moveTo(left, top);
                presentationWindow.resizeTo(width, height);
            } catch (e) {
                console.warn('Window positioning not fully supported in this browser.');
            }

            presentationWindow.focus();

            // Listen for window close event
            presentationWindow.addEventListener('beforeunload', () => {
                presentationWindow = null; // Reset when the window is closed
            });
        } else {
            alert('Failed to open presentation window. Please allow pop-ups for this site.');
        }
    };

    const handleLaunchPresentation = () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
        const presUrl = `https://ariaslife.com/temp/agent_tools/presentation/pres_setup.html?a=${userToken}&b=${userId}`;
        openPres(presUrl);
    };

const openPhone = (phoneFile) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    // Set width to half of the screen width
    const width = Math.round(screenWidth / 2);

    // Full height
    const height = screenHeight;

    // Position the window on the right half of the screen
    const left = screenWidth - width; // Start from the middle of the screen
    const top = 0; // Align to the top of the screen

    const phoneWindow = window.open(
        phoneFile,
        '_blank',
        `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
    );

    if (phoneWindow) {
        phoneWindow.focus();
    } else {
        alert('Failed to open phone scripts window. Please allow pop-ups for this site.');
    }
};


    return (
        <div className="main-buttons-row">
            <button
                className="main-custom-button main-gray-button"
                onClick={() => window.open('https://ariaslife.mykajabi.com/', '_blank', 'noopener noreferrer')}
            >
                New Agent Training Course
            </button>

            <button
                onClick={() => openPhone('https://ariaslife.com/temp/agent_tools/phone_scripts/phone_scripts.html')}
                className="main-custom-button main-orange-button"
            >
                Phone Scripts
            </button>
            <button
                onClick={() => handleLaunchPresentation()}
                className="main-custom-button main-green-button"
            >
                Launch Presentation
            </button>
        </div>
    );
};

export default MainButtons;
