import React, { useEffect, useState } from "react";
import Placeholder from "../utils/Placeholder";
import { Tab, Tabs } from "react-bootstrap";

const AgentProgressTable = () => {
  const [agents, setAgents] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const [actionStatus, setActionStatus] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [massAction, setMassAction] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "agentName",
    direction: "asc",
  });

  const filteredProgressKeys = [
    "contract_2nd",
    "bonus_90d",
    "bonus_after_90d",
    "practice_pres",
    "refs_25",
  ];
  const totalProgressItems = filteredProgressKeys.length + 10 + 25;

  const toggleSelection = (agentName) => {
    setSelectedAgents((prev) =>
      prev.includes(agentName)
        ? prev.filter((name) => name !== agentName)
        : [...prev, agentName]
    );
  };
  
  const handleMassAction = async (action) => {
    if (massAction === null) {
      // Set action for confirmation
      setMassAction(action);
      return;
    }
  
    try {
      const requests = selectedAgents.map((agentName) => {
        const selectedAgent = agents.find((agent) => agent.lagnname === agentName);
        return fetch(
          action === "Pass"
            ? `https://ariaslogin-4a95935f6093.herokuapp.com/api/pass-user`
            : `https://ariaslogin-4a95935f6093.herokuapp.com/api/fail-user`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId: selectedAgent.user_id }),
          }
        );
      });
  
      await Promise.all(requests);
      setProgressData((prevData) =>
        prevData.filter((agent) => !selectedAgents.includes(agent.agentName))
      );
      setSelectedAgents([]); // Clear selection after action
      setMassAction(null); // Reset mass action state
      console.log(`Mass ${action} action completed successfully.`);
    } catch (error) {
      console.error("Error during mass action:", error);
      setMassAction(null); // Reset state in case of error
    }
  };
  
  
  const calculateProgress = (responses) => {
    let completedItems = filteredProgressKeys.filter((key) => {
      if (key === "contract_2nd" || key === "bonus_90d" || key === "bonus_after_90d") {
        return (
          (key === "bonus_90d" && responses[key] === "750") ||
          (key === "bonus_after_90d" && responses[key] === "250") ||
          responses[key]
        );
      }
      return responses[key];
    }).length;

    completedItems += Math.min(responses.practice_pres || 0, 10);
    completedItems += Math.min(responses.refs_25 || 0, 25);

    return (completedItems / totalProgressItems) * 100;
  };

  const fetchAgentProgress = async (agentChecklist) => {
    return calculateProgress(agentChecklist);
  };

  const loadProgressData = async () => {
    setLoading(true);

    try {
      let agentsData;
      let validMGAs = [];

      if (userId === "101") {
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/get-unreleased-users-checklist`);
        const data = await response.json();
        if (data.success) {
          agentsData = data.data;
          console.log("Unreleased users with checklist data:", agentsData);
        } else {
          console.error("Failed to fetch unreleased users with checklist data:", data.message);
          return;
        }
      } else {
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId }),
        });
        const data = await response.json();
        if (data.success) {
          validMGAs = data.data.map((agent) => agent.mga);
        } else {
          console.error("Failed to fetch agents:", data.message);
          return;
        }

        const checklistResponse = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/get-unreleased-users-checklist`);
        const checklistData = await checklistResponse.json();
        if (checklistData.success) {
          agentsData = checklistData.data.filter((agent) => validMGAs.includes(agent.mga));
        } else {
          console.error("Failed to fetch unreleased users with checklist data:", checklistData.message);
          return;
        }
      }

      setAgents(agentsData);

      const agentProgressPromises = agentsData.map(async (agent) => {
        const progress = await fetchAgentProgress(agent);
        return {
          agentName: agent.lagnname,
          progress,
          releaseScheduled: agent.release_scheduled,
          timeSubmitted: agent.time_submitted,
          mga: agent.mga,
          passed: agent.passed,
          released: agent.released,
        };
      });

      const progressArray = await Promise.all(agentProgressPromises);
      setProgressData(progressArray);
    } catch (error) {
      console.error("Error loading progress data:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadProgressData();
  }, []);

  useEffect(() => {
    setSortConfig({ key: "releaseScheduled", direction: "desc" });
  }, []);
  
  useEffect(() => {
    setSortConfig({ key: "progress", direction: "desc" });
  }, []);
  
  const handleSort = (key) => {
    const newDirection =
      sortConfig.key === key
        ? sortConfig.direction === "asc"
          ? "desc"
          : "asc"
        : "asc";
  
    setSortConfig({ key, direction: newDirection });
  };
  
  

  const sortedData = (data, sortKey = sortConfig.key, sortDirection = sortConfig.direction) => {
    return [...data]
      .filter((agent) => {
        if (!agent) {
          console.warn("Found undefined agent in sortedData:", agent);
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const aValue =
          sortKey === "releaseScheduled"
            ? new Date(a[sortKey] || 0)
            : a[sortKey] || 0;
  
        const bValue =
          sortKey === "releaseScheduled"
            ? new Date(b[sortKey] || 0)
            : b[sortKey] || 0;
  
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
  };
  
  
  

  const deduplicateAgents = (data) => {
    const uniqueAgents = new Map();
    data.forEach((agent) => {
      if (!uniqueAgents.has(agent.agentName)) {
        uniqueAgents.set(agent.agentName, agent);
      }
    });
    return Array.from(uniqueAgents.values());
  };
  
  const filterData = (data) => {
    const deduplicatedData = deduplicateAgents(data); // Deduplicate agents
    return deduplicatedData.filter(
      (agent) =>
        agent.agentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (agent.mga && agent.mga.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };
  
  const handleAction = (agentName, action) => {
    setActionStatus((prevState) => ({
      ...prevState,
      [agentName]: action,
    }));
  };
  
  const handleConfirm = async (agentName) => {
    const action = actionStatus[agentName];
    // Find the selected agent by `agentName`
    const selectedAgent = agents.find((agent) => agent.lagnname === agentName);

    if (!selectedAgent) {
        console.error("Agent not found:", agentName);
        return;
    }

    console.log(`Initiating ${action} action for agentName: ${agentName}, userId: ${selectedAgent.user_id}`);

    try {
        const response = await fetch(
            action === "Pass"
                ? `https://ariaslogin-4a95935f6093.herokuapp.com/api/pass-user`
                : `https://ariaslogin-4a95935f6093.herokuapp.com/api/fail-user`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId: selectedAgent.user_id }),
            }
        );

        const data = await response.json();
        if (data.success) {
            console.log(`Successfully confirmed ${action} for agentName: ${agentName}, userId: ${selectedAgent.user_id}`);
            // Update local state to remove the agent from `progressData`
            setProgressData((prevData) =>
                prevData.filter((agent) => agent.agentName !== agentName)
            );
            setActionStatus((prevState) => {
                const updatedState = { ...prevState };
                delete updatedState[agentName]; // Clear action status for this agent
                return updatedState;
            });
        } else {
            console.error("Action failed:", data.message);
        }
    } catch (error) {
        console.error("Error confirming action:", error);
    }
};


const formatName = (name) => {
  if (!name) return "N/A"; // Handle empty or null values
  const parts = name.split(" ").filter(Boolean); // Split and remove extra spaces
  const [last, first, middle, suffix] = parts;

  if (parts.length === 2) {
    return `${first} ${last}`; // Format as First Last
  } else if (parts.length === 3) {
    if (middle && middle.length === 1) {
      return `${first} ${middle} ${last}`; // Format as First Middle Last
    }
    return `${first} ${last} ${middle}`; // Format as First Last Suffix
  } else if (parts.length === 4) {
    return `${first} ${middle} ${last} ${suffix}`; // Format as First Middle Last Suffix
  }

  return name; // Return original name if format is unexpected
};

const renderTable = (filteredData, columns, sortKey = sortConfig.key, sortDirection = sortConfig.direction, rowStyle = () => ({})) => (
  <div className="recruiting-table-container">
    <table className="recruiting-table">
      <thead>
        <tr>
          {columns.map(({ key, label }) => (
            <th key={key} onClick={() => handleSort(key)}>
              {label} {sortConfig.key === key ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData(filteredData, sortKey, sortDirection).map((agent, index) => {
          if (!agent) return null; // Skip undefined rows
          return (
            <tr key={index} style={rowStyle(agent)}>
              {columns.map(({ key, format }) => (
                <td key={key}>
                  {format ? format(agent[key], agent) : key === "agentName" || key === "mga" ? formatName(agent[key]) : agent[key] || "N/A"}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);


  const [searchQuery, setSearchQuery] = useState("");

  if (loading) {
    return <Placeholder />;
  }

  return (
    <div className="agent-progress-table-container">
      <div className="search-bar mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search by agent name or MGA..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <Tabs defaultActiveKey="upcomingReleases" id="progress-tabs" className="mb-3">

      <Tab eventKey="upcomingReleases" title="Upcoming Releases">
      <div className="bulk-action-container">
  {userId === "101" && selectedAgents.length > 0 ? (
    <div className="bulk-action-buttons">
      {massAction === null ? (
        <>
          <button
            className="insured-button"
            onClick={() => handleMassAction("Pass")}
          >
            Pass All
          </button>
          <button
            className="insured-button"
            onClick={() => handleMassAction("Fail")}
          >
            Fail All
          </button>
        </>
      ) : (
        <>
          {massAction === "Pass" && (
            <button
              className="insured-button"
              onClick={() => handleMassAction("Pass")}
            >
              Confirm Pass All
            </button>
          )}
          {massAction === "Fail" && (
            <button
              className="insured-button"
              onClick={() => handleMassAction("Fail")}
            >
              Confirm Fail All
            </button>
          )}
          <button
            className="insured-button"
            onClick={() => setMassAction(null)}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  ) : null}
</div>

  {renderTable(
    filterData(
      progressData.filter(
        (agent) => agent.releaseScheduled !== null && agent.passed !== "y"
      )
    ),
    [
      { key: "agentName", label: "Agent Name" },
      {
        key: "releaseScheduled",
        label: "Release Scheduled",
        format: (date) => new Date(date).toLocaleString(),
      },
      { key: "mga", label: "MGA" },
      ...(userId === "101"
        ? [
          {
            key: "actions",
            label: "Actions",
            format: (_, agent) => (
              <div>
                {actionStatus[agent.agentName] === "Pass" ? (
                  <>
                    <button
                      className="insured-button"
                      onClick={() => handleConfirm(agent.agentName)}
                    >
                      Confirm Pass
                    </button>
                    <button
                      className="insured-button"
                      onClick={() =>
                        setActionStatus((prevState) => ({
                          ...prevState,
                          [agent.agentName]: null,
                        }))
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : actionStatus[agent.agentName] === "Fail" ? (
                  <>
                    <button
                      className="insured-button"
                      onClick={() => handleConfirm(agent.agentName)}
                    >
                      Confirm Fail
                    </button>
                    <button
                      className="insured-button"
                      onClick={() =>
                        setActionStatus((prevState) => ({
                          ...prevState,
                          [agent.agentName]: null,
                        }))
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="insured-button"
                      onClick={() => handleAction(agent.agentName, "Pass")}
                    >
                      Pass
                    </button>
                    <button
                      className="insured-button"
                      onClick={() => handleAction(agent.agentName, "Fail")}
                    >
                      Fail
                    </button>
                  </>
                )}
              </div>
            ),
          }
          ,
            {
              key: "select",
              label: (
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSelectedAgents(
                      isChecked
                        ? progressData
                            .filter(
                              (agent) =>
                                agent.releaseScheduled !== null &&
                                agent.passed !== "y"
                            )
                            .map((agent) => agent.agentName)
                        : []
                    );
                  }}
                  checked={
                    selectedAgents.length ===
                    progressData.filter(
                      (agent) =>
                        agent.releaseScheduled !== null && agent.passed !== "y"
                    ).length
                  }
                />
              ),
              format: (_, agent) => (
                <input
                  type="checkbox"
                  checked={selectedAgents.includes(agent.agentName)}
                  onChange={() => toggleSelection(agent.agentName)}
                />
              ),
            },
          ]
        : []),
    ],
    "releaseScheduled", // Custom sort key
    "desc", // Custom sort direction
    (agent) =>
      new Date(agent.releaseScheduled) < new Date()
        ? { backgroundColor: "rgba(255, 0, 0, 0.1)" } // Light red tint
        : {}
  )}
</Tab>


<Tab eventKey="pendingScheduling" title="Pending Scheduling">
  {renderTable(
    filterData(
      progressData.filter(
        (agent) => agent.progress === 100 && agent.releaseScheduled === null
      )
    ),
    [
      { key: "agentName", label: "Agent Name" },
      {
        key: "progress",
        label: "Progress (%)",
        format: (progress) => `${Math.round(progress)}%`,
      },
      { key: "mga", label: "MGA" },
    ]
  )}
</Tab>

<Tab eventKey="checklistInProgress" title="Checklist in Progress">
  {renderTable(
    filterData(
      progressData.filter((agent) => {
        // Log each row and the filter conditions
        const passesProgress = agent.progress < 100;
        const passesReleased = agent.released === 0;
        return passesProgress && passesReleased;
      })
    ),
    [
      { key: "agentName", label: "Agent Name" },
      {
        key: "progress",
        label: "Progress (%)",
        format: (progress) => `${Math.round(progress)}%`,
      },
      { key: "mga", label: "MGA" },
    ]
  )}
</Tab>
      <Tab eventKey="passedReleases" title="Passed Releases">
    {renderTable(
      filterData(progressData.filter((agent) => agent.passed === "y")),
      [
        { key: "agentName", label: "Agent Name" },
        {
          key: "releaseScheduled",
          label: "Release Date",
          format: (date) => new Date(date).toLocaleString(),
        },
        { key: "mga", label: "MGA" },
      ],
      "releaseScheduled", // Custom sort key
      "desc" // Custom sort direction
    )}
  </Tab>
      </Tabs>
    </div>
  );
};

export default AgentProgressTable;