import React, { useState, useEffect } from 'react';
import './AgentCountBadge.css';
import TrophyCaseModal from '../Leaderboards/TrophyCaseModal'; // Ensure this path is correct based on your project structure
const AgentCountBadge = ({ count, agentNames, agentData, dateRange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showTrophyModal, setShowTrophyModal] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState(null);
    const [totalDays, setTotalDays] = useState(0);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    console.log('agentData:', agentData);
    
    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const toggleOverlay = () => {
        setIsOverlayVisible(!isOverlayVisible);
    };

    const closeOverlay = () => {
        setIsOverlayVisible(false);
    };
    const computeAgentTotals = (agent) => {
        const totals = {
            calls: 0,
            appts: 0,
            sits: 0,
            sales: 0,
            refs: 0,
            alp: 0,
            refAppt: 0,
            refSit: 0,
            refSale: 0,
            refAlp: 0,
            reportDates: new Set(),
        };
        agentData.forEach(item => {
            if (item.agent === agent) {
                totals.calls += parseFloat(item.calls || 0);
                totals.appts += parseFloat(item.appts || 0);
                totals.sits += parseFloat(item.sits || 0);
                totals.sales += parseFloat(item.sales || 0);
                totals.refs += parseFloat(item.refs || 0);
                totals.alp += parseFloat(item.alp || 0);
                totals.refAppt += parseFloat(item.refAppt || 0);
                totals.refSit += parseFloat(item.refSit || 0);
                totals.refSale += parseFloat(item.refSale || 0);
                totals.refAlp += parseFloat(item.refAlp || 0);
                totals.reportDates.add(item.reportDate);
            }
        });
    
        // Calculate additional statistics
        totals.daysReported = totals.reportDates.size;
        totals.callsToSetAppt = totals.appts > 0 ? (totals.calls / totals.appts).toFixed(0) : 0;
        totals.callsToSit = totals.sits > 0 ? (totals.calls / totals.sits).toFixed(0) : 0;
        totals.showRatio = totals.appts > 0 ? ((totals.sits / totals.appts) * 100).toFixed(0) + '%' : '0%';
        totals.closeRatio = totals.sits > 0 ? ((totals.sales / totals.sits) * 100).toFixed(0) + '%' : '0%';
        totals.alpPerSit = totals.sits > 0 ? (totals.alp / totals.sits).toFixed(2) : 0;
        totals.alpPerSale = totals.sales > 0 ? (totals.alp / totals.sales).toFixed(2) : 0;
        totals.refsPerSit = totals.sits > 0 ? (totals.refs / totals.sits).toFixed(2) : 0;
        totals.refClose = totals.refSit > 0 ? ((totals.refSale / totals.refSit) * 100).toFixed(0) + '%' : '0%';
        totals.alpPerRefSale = totals.refSale > 0 ? (totals.refAlp / totals.refSale).toFixed(2) : 0;
        totals.alpPerRefCollected = totals.refs > 0 ? (totals.refAlp / totals.refs).toFixed(2) : 0;
    
        return totals;
    };
    const getMGA = (agent) => {
        const agentInfo = agentData.find(item => item.agent === agent);
        return agentInfo && agentInfo.MGA ? agentInfo.MGA : agent;
    };
    
    const getLastName = (fullName) => {
        const nameParts = fullName.trim().split(' ');
        return nameParts[0]; // Assuming the last name is the first part of the name string
    };
    
    const rearrangeAgentName = (fullName) => {
        const nameParts = fullName.trim().split(' ');
        const formattedNameParts = nameParts.map(namePart =>
            namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase()
        );
    
        if (formattedNameParts.length > 1) {
            // Move the first part (Last) to the end of the array
            const firstPart = formattedNameParts.shift();
            formattedNameParts.push(firstPart);
            return formattedNameParts.join(' ');
        }
        return formattedNameParts.join(' '); // If there's only one name part, format and return it
    };
    
    const sortedAgentNames = [...agentNames].sort((a, b) => {
        const mgaA = getMGA(a).toLowerCase();
        const mgaB = getMGA(b).toLowerCase();
        if (mgaA < mgaB) return -1;
        if (mgaA > mgaB) return 1;
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    });
    
    const openTrophyModal = (agentName) => {
        setSelectedAgentName(agentName);
        setShowTrophyModal(true);
    };

    const closeTrophyModal = () => {
        setShowTrophyModal(false);
        setSelectedAgentName(null);
    };

    useEffect(() => {
        const calculateTotalDays = () => {
            const [start, end] = dateRange.split(' - ');
            const startDate = new Date(start);
            const endDate = new Date(end);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Include the start and end date
            setTotalDays(diffDays);
        };
        calculateTotalDays();
    }, [dateRange]);

    return (
        <div className="agent-count-container">
            <div className='app-container'>
<div className="agent-count-badge" title="Agent Count" onClick={toggleOverlay}>
    {count}
</div>

            {isOverlayVisible && (
                <div className="custom-overlay">
                    <div className="overlay-content">
                        <button className="close-button" onClick={closeOverlay}>X</button>
                        <h5>Activity & Stats for {dateRange}</h5>
                            
        <table className="agentcount-table">
            <thead>
                <tr>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 4 }}>Agent Name</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Calls</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Appts</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Sits</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Sales</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Refs</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>ALP</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Ref Appt</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Ref Sit</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Ref Sale</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Ref ALP</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Days Reported</th> {/* New Column */}
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Calls/Set Appt</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Calls/Sit</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Show Ratio</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Close Ratio</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>ALP/Sit</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>ALP/Sale</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Refs/Sit</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>Ref Close</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>ALP/Ref Sale</th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#00558c', zIndex: 2 }}>ALP/Ref Col</th>
                </tr>
            </thead>
            <tbody>
                {sortedAgentNames.reduce((acc, name, index) => {
                    const totals = computeAgentTotals(name);
                    const mga = getMGA(name);
                    const previousMGA = index > 0 ? getMGA(sortedAgentNames[index - 1]) : '';

                    if (mga !== previousMGA) {
                        if (index > 0) {
                            // Add totals row for previous MGA section
                            const previousTotals = acc.mgaTotals;
                            acc.rows.push(
                                <tr key={`totals-${previousMGA}`} className="mga-totals-row">
                <td style={{ backgroundColor: '#d3d3d3', whiteSpace: 'nowrap' }}>
                    <strong>{getLastName(previousMGA)} Totals</strong>
                </td>                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.calls}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.appts}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.sits}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.sales}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refs}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.alp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refAppt}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refSit}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refSale}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refAlp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}></td> {/* Empty cell for Days Reported */}
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.callsToSetAppt}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{Math.round(previousTotals.callsToSit)}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((previousTotals.sits / previousTotals.appts) * 100).toFixed(0) + '%'}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((previousTotals.sales / previousTotals.sits) * 100).toFixed(0) + '%'}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.alpPerSit}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.alpPerSale}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.refsPerSit}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((previousTotals.refSale / previousTotals.refSit) * 100).toFixed(0) + '%'}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.alpPerRefSale}</strong></td>
                                    <td style={{ backgroundColor: '#d3d3d3' }}><strong>{previousTotals.alpPerRefCollected}</strong></td>
                                </tr>
                            );
                        }

                        acc.rows.push(
                            <tr key={`${mga}-${index}`}>
                                <th colSpan="1" style={{ position: 'sticky',whiteSpace: 'nowrap', zIndex: 1, fontSize: '12px', backgroundColor: 'grey' }}><strong>{getLastName(mga)}</strong></th> {/* Update colSpan */}
                            </tr>
                        );

                        // Reset totals for new MGA section
                        acc.mgaTotals = {
                            calls: 0,
                            appts: 0,
                            sits: 0,
                            sales: 0,
                            refs: 0,
                            alp: 0,
                            refAppt: 0,
                            refSit: 0,
                            refSale: 0,
                            refAlp: 0,
                            callsToSetAppt: 0,
                            callsToSit: 0,
                            showRatio: 0,
                            closeRatio: 0,
                            alpPerSit: 0,
                            alpPerSale: 0,
                            refsPerSit: 0,
                            refClose: 0,
                            alpPerRefSale: 0,
                            alpPerRefCollected: 0,
                            daysReported: 0 // Add daysReported
                        };
                    }

                    // Add agent row
                    acc.rows.push(
                        <tr key={index}>
                <td style={{ position: 'sticky', whiteSpace: 'nowrap', zIndex: 1, fontSize: '12px' }}>
                    <span className="agent-name-link" onClick={() => openTrophyModal(name)}>
                        {rearrangeAgentName(name)} {/* Rearrange name to First Middle Last */}
                    </span>
                </td>
                            <td>{totals.calls}</td>
                            <td>{totals.appts}</td>
                            <td>{totals.sits}</td>
                            <td>{totals.sales}</td>
                            <td>{totals.refs}</td>
                            <td>{totals.alp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{totals.refAppt}</td>
                            <td>{totals.refSit}</td>
                            <td>{totals.refSale}</td>
                            <td>{totals.refAlp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{totals.daysReported}/{totalDays}</td>
                            <td>{totals.callsToSetAppt}</td>
                            <td>{totals.callsToSit}</td>
                            <td>{totals.showRatio}</td>
                            <td>{totals.closeRatio}</td>
                            <td>{totals.alpPerSit}</td>
                            <td>{totals.alpPerSale}</td>
                            <td>{totals.refsPerSit}</td>
                            <td>{totals.refClose}</td>
                            <td>{totals.alpPerRefSale}</td>
                            <td>{totals.alpPerRefCollected}</td>
                        </tr>
                    );

                    // Update totals for current MGA section
                    acc.mgaTotals.calls += totals.calls;
                    acc.mgaTotals.appts += totals.appts;
                    acc.mgaTotals.sits += totals.sits;
                    acc.mgaTotals.sales += totals.sales;
                    acc.mgaTotals.refs += totals.refs;
                    acc.mgaTotals.alp += totals.alp;
                    acc.mgaTotals.refAppt += totals.refAppt;
                    acc.mgaTotals.refSit += totals.refSit;
                    acc.mgaTotals.refSale += totals.refSale;
                    acc.mgaTotals.refAlp += totals.refAlp;
                    acc.mgaTotals.daysReported += totals.daysReported; // Update daysReported

                    acc.mgaTotals.callsToSetAppt = acc.mgaTotals.calls > 0 ? (acc.mgaTotals.calls / acc.mgaTotals.appts).toFixed(2) : 0;
                    acc.mgaTotals.callsToSit = acc.mgaTotals.calls > 0 ? (acc.mgaTotals.calls / acc.mgaTotals.sits).toFixed(2) : 0;
                    acc.mgaTotals.showRatio = acc.mgaTotals.appts > 0 ? (acc.mgaTotals.sits / acc.mgaTotals.appts).toFixed(2) : 0;
                    acc.mgaTotals.closeRatio = acc.mgaTotals.sits > 0 ? (acc.mgaTotals.sales / acc.mgaTotals.sits).toFixed(2) : 0;
                    acc.mgaTotals.alpPerSit = acc.mgaTotals.sits > 0 ? (acc.mgaTotals.alp / acc.mgaTotals.sits).toFixed(2) : 0;
                    acc.mgaTotals.alpPerSale = acc.mgaTotals.sales > 0 ? (acc.mgaTotals.alp / acc.mgaTotals.sales).toFixed(2) : 0;
                    acc.mgaTotals.refsPerSit = acc.mgaTotals.sits > 0 ? (acc.mgaTotals.refs / acc.mgaTotals.sits).toFixed(2) : 0;
                    acc.mgaTotals.refClose = acc.mgaTotals.refSit > 0 ? (acc.mgaTotals.refSale / acc.mgaTotals.refSit).toFixed(2) : 0;
                    acc.mgaTotals.alpPerRefSale = acc.mgaTotals.refSale > 0 ? (acc.mgaTotals.refAlp / acc.mgaTotals.refSale).toFixed(2) : 0;
                    acc.mgaTotals.alpPerRefCollected = acc.mgaTotals.refs > 0 ? (acc.mgaTotals.refAlp / acc.mgaTotals.refs).toFixed(2) : 0;

                    // Handle the final MGA section totals row
                    if (index === sortedAgentNames.length - 1) {
                        const currentTotals = acc.mgaTotals;
                        acc.rows.push(
                            <tr key={`totals-${mga}`} className="mga-totals-row">
            <td style={{ backgroundColor: '#d3d3d3', whiteSpace: 'nowrap' }}>
                <strong>{getLastName(mga)} Totals</strong>
            </td>                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.calls}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.appts}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.sits}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.sales}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refs}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.alp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refAppt}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refSit}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refSale}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refAlp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.daysReported}</strong></td> {/* New Column */}
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.callsToSetAppt}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{Math.round(currentTotals.callsToSit)}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((currentTotals.sits / currentTotals.appts) * 100).toFixed(0) + '%'}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((currentTotals.sales / currentTotals.sits) * 100).toFixed(0) + '%'}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.alpPerSit}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.alpPerSale}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.refsPerSit}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{((currentTotals.refSale / currentTotals.refSit) * 100).toFixed(0) + '%'}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.alpPerRefSale}</strong></td>
                                <td style={{ backgroundColor: '#d3d3d3' }}><strong>{currentTotals.alpPerRefCollected}</strong></td>
                            </tr>
                        );
                    }

                    return acc;
                }, { rows: [], mgaTotals: { calls: 0, appts: 0, sits: 0, sales: 0, refs: 0, alp: 0, refAppt: 0, refSit: 0, refSale: 0, refAlp: 0, callsToSetAppt: 0, callsToSit: 0, showRatio: 0, closeRatio: 0, alpPerSit: 0, alpPerSale: 0, refsPerSit: 0, refClose: 0, alpPerRefSale: 0, alpPerRefCollected: 0, daysReported: 0 } }).rows}
            </tbody>
        </table>
        </div>
        </div>
            )}
            {showTrophyModal && (
                <TrophyCaseModal
                    agentName={selectedAgentName}
                    onClose={closeTrophyModal}
                />
            )}
        </div>
        </  div>
    );
};

export default AgentCountBadge;
