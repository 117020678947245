import React, { useState, useEffect } from "react";

const MoreReportingStatus = ({ amoreData, filters }) => {
  const [mgaData, setMgaData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [showOnlyNotReported, setShowOnlyNotReported] = useState(false); // Toggle state for filtering

  useEffect(() => {
    const fetchMgaData = async () => {
      try {
        const response = await fetch(
          "https://ariaslogin-4a95935f6093.herokuapp.com/api/get-mgas-with-activeusers"
        );
        const result = await response.json();
        console.log("Fetched MGA Data:", result);
        if (result.success) {
          // Filter MGAs with hide = 'n' and active = 'y'
          const filteredMGAs = result.data.filter(
            (mga) => mga.hide === "n" && mga.active === "y"
          );
          setMgaData(filteredMGAs);
        } else {
          console.error("Failed to fetch MGAs:", result.message);
        }
      } catch (error) {
        console.error("Error fetching MGAs:", error);
      }
    };

    fetchMgaData();
  }, []);

  useEffect(() => {
    if (mgaData.length) {
      // Apply MGA, RGA, and Tree filters
      const { MGA, RGA, Tree } = filters;

      const filtered = mgaData.filter((mga) => {
        if (MGA && mga.lagnname !== MGA) return false;
        if (RGA && mga.rga !== RGA) return false;
        if (Tree && mga.tree !== Tree) return false;
        return true;
      });

      // Check if MGAs are reported and/or reported automatically
      const reportedMGAs = amoreData.map((item) => ({
        lagnname: item.MGA,
        botEnter: item.bot_enter === 1, // Mark reported automatically
      }));

      // Map MGAs and add `isReported` and `isReportedAutomatically` properties
      const finalList = filtered.map((mga) => {
        const reportInfo = reportedMGAs.find((report) => report.lagnname === mga.lagnname);
        return {
          ...mga,
          isReported: !!reportInfo,
          isReportedAutomatically: reportInfo?.botEnter || false,
        };
      });

      // Group by Tree and calculate totals
      const grouped = finalList.reduce((acc, mga) => {
        if (!acc[mga.tree]) {
          acc[mga.tree] = {
            mgas: [],
            total: 0,
            reported: 0,
          };
        }
        acc[mga.tree].mgas.push(mga);
        acc[mga.tree].total += 1;
        if (mga.isReported) acc[mga.tree].reported += 1;
        return acc;
      }, {});

      // Sort MGAs alphabetically within each tree
      Object.keys(grouped).forEach((tree) => {
        grouped[tree].mgas = grouped[tree].mgas.sort((a, b) =>
          a.lagnname.localeCompare(b.lagnname)
        );
      });

      // Apply filtering to remove reported MGAs if toggled
      if (showOnlyNotReported) {
        Object.keys(grouped).forEach((tree) => {
          grouped[tree].mgas = grouped[tree].mgas.filter((mga) => !mga.isReported);
          // Add a placeholder row if no MGAs remain for the tree
          if (grouped[tree].mgas.length === 0) {
            grouped[tree].mgas = [{ placeholder: true }];
          }
        });
      }

      setGroupedData(grouped);
    }
  }, [filters, mgaData, amoreData, showOnlyNotReported]);

  const reformatName = (name) => {
    if (!name) return "Unknown";
    const capitalize = (word) =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    const parts = name.split(" ");
    const [last, first, ...rest] = parts;
    return `${capitalize(first || "")} ${capitalize(last || "")}`;
  };

  return (
    <div className="more-leaderboard-container">
        <div className="more-header-with-toggle-and-filters">
        <h5>Reporting Status</h5>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
        <button
          onClick={() => setShowOnlyNotReported(!showOnlyNotReported)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "4px 8px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "10px",
              textDecoration: "none",
            }}
          >
            {showOnlyNotReported ? "Show All" : "Filter Reported"}
          </span>
        </button>
      </div>
      </div>

      {Object.keys(groupedData).length > 0 ? (
        <table className="recruiting-table">
          <tbody>
            {Object.entries(groupedData).map(([tree, { mgas, total, reported }]) => (
              <React.Fragment key={tree}>
                {/* Row for Tree */}
                <tr>
                  <td colSpan="3" style={{ fontWeight: "bold" }}>
                    {reformatName(tree)}
                  </td>
                </tr>
                {/* Rows for MGAs under the Tree */}
                {mgas.map((mga, index) =>
                  mga.placeholder ? (
                    <tr key={`${tree}-placeholder`}>
                      <td colSpan="3" style={{ textAlign: "center", fontStyle: "italic" }}>
                        All MGAs reported for this Tree
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td></td>
                      <td>{reformatName(mga.lagnname)}</td>
                      <td
                        style={{
                          color: mga.isReported
                            ? mga.isReportedAutomatically
                              ? "orange"
                              : "green"
                            : "black",
                        }}
                      >
                        {mga.isReportedAutomatically
                          ? "Reported Automatically"
                          : mga.isReported
                          ? "Reported"
                          : "Not Reported"}
                      </td>
                    </tr>
                  )
                )}
                {/* Total Row */}
                <tr>
                  <td></td>
                  <td
                    colSpan="2"
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    Total Reported: {reported} / {total}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No MGAs match the current filters.</p>
      )}
    </div>
  );
};

export default MoreReportingStatus;
