import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Modal, Link } from 'react-bootstrap';
import logo from '../../img/globe1.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './Register';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAriasModal, setShowAriasModal] = useState(false); // New state for Arias modal
  const [showRegister, setShowRegister] = useState(false); // Toggle for Register component
  const [registerData, setRegisterData] = useState({}); // Store data for Register component
  const [showChangePassword, setShowChangePassword] = useState(false); // Toggle for ChangePassword component

  const navigate = useNavigate();

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Expiration in days
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/;Secure;SameSite=Strict`;
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
  
    if (username.toLowerCase() === 'arias') {
      setShowAriasModal(true); // Show special modal for "arias" username
      return; // Prevent form submission and API call
    }
  
    setIsLoading(true); // Enable loading overlay
  
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/newlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json(); // Convert response to JSON
  
      setIsLoading(false); // Disable loading overlay
  
      if (data.success && data.message === 'Login successful') {
        console.log('Login successful:', data);
        
        // Prepare the data to store
        const userInfo = {
          email: data.email || '',
          phone: data.phone || '',
          profpic: data.profpic || 'http://www.gravatar.com/avatar/?d=mp',
          esid: data.esid || '',
          lagnname: data.agnName || '',
          mga: data.mgaRgaData?.mga || '',
          userRole: data.clname || '',
          userId: data.userId || '',
        };
        
       setCookie('authToken', data.token, 1); 
        // Store in localStorage
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userRole', data.clname);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('agnName', data.agnName);
        localStorage.setItem('agnNum', data.agtnum);
        localStorage.setItem('email', data.email || '');
        localStorage.setItem('phone', data.phone || '');
        localStorage.setItem('profpic', data.profpic || 'http://www.gravatar.com/avatar/?d=mp');
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
  
        if (data.mgaRgaData) {
          localStorage.setItem('mgaRgaData', JSON.stringify(data.mgaRgaData));
        }
  
        // Store in sessionStorage
        sessionStorage.setItem('userToken', data.token);
        sessionStorage.setItem('userRole', data.clname);
        sessionStorage.setItem('userId', data.userId);
        sessionStorage.setItem('agnName', data.agnName);
        sessionStorage.setItem('agnNum', data.agtnum);
        sessionStorage.setItem('email', data.email || '');
        sessionStorage.setItem('phone', data.phone || '');
        sessionStorage.setItem('profpic', data.profpic || 'http://www.gravatar.com/avatar/?d=mp');
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  
        if (data.mgaRgaData) {
          sessionStorage.setItem('mgaRgaData', JSON.stringify(data.mgaRgaData));
        }
  
        navigate('/');
        window.location.reload(); // Reload the page to update the navbar
      } else if (data.success && data.message === 'Please complete account setup') {
        setRegisterData({
          id: data.id,
          lagnname: data.lagnname,
          esid: data.esid,
          email: data.email,
          phone: data.phone,
          screenName: data.screenName,
        });
        setShowRegister(true);
      } else {
        alert('Login failed: ' + (data.message || 'Unknown error'));
      }
    } catch (error) {
      setIsLoading(false); // Disable loading overlay
      alert('Login failed: ' + error.message);
    }
  };
  
  const handleForgotPassword = () => {
    setShowChangePassword(true);
  };
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value); // Remove `.toUpperCase()` to preserve original input
  };
  
  const openPres = (presFile) => {
    const screenWidth = window.screen.availWidth;
    const width = screenWidth > 3800 ? Math.round(screenWidth * 0.35) : Math.round(screenWidth * 0.7);
    const height = Math.round(window.screen.availHeight * 0.7);
    const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2.61) : 0;
    const top = window.screen.availHeight - height;

    const presWindow = window.open(
      presFile,
      '_blank',
      `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
    );
    presWindow.focus();
  };

  const openPhone = (phoneFile) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const width = screenWidth > 3800 ? Math.round(screenWidth * 0.5) : window.screen.availWidth;
    const height = window.screen.availHeight;
    const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2) : 0;
    const top = 0;

    const phoneWindow = window.open(
      phoneFile,
      '_blank',
      `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
    );
    phoneWindow.focus();
  };

  return (
    <>
      {showRegister ? (
        <Register
          onBackToLogin={() => setShowRegister(false)}
          id={registerData.id} // Pass the id to Register component
          lagnname={registerData.lagnname}
          esid={registerData.esid}
          email={registerData.email}
          phone={registerData.phone}
          screenName={registerData.screenName}
        />
      ) : showChangePassword ? (
<ForgotPassword onBackToLogin={() => setShowChangePassword(false)} />
      ) : (
        <>
          {isLoading && (
            <div className="globe-loading-overlay">
              <img src={logo} alt="Loading" className="globe-loading-animation" />
            </div>
          )}
          <div className="app-container">
            <div className="login-container">
              {/* Left Section: Login Card */}
              <div className="login-card">
                <h2 className="section-header">Login as Agent</h2> {/* Left Header */}
                <Card className="w-100" style={{ maxWidth: '400px' }}>
                  <Card.Body>
                    <div className="text-center"></div>
                    <form onSubmit={handleLogin}>
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          required
                          value={password}
                          onChange={handlePasswordChange}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </div>
                      <Button className="w-100 mt-4" style={{ backgroundColor: '#00558c' }} type="submit">
                        Log In
                      </Button>
                    </form>
                    <div className="text-center mt-3">
                      <a href="#" onClick={() => setShowInfoModal(true)}>
                        I don't know my login/don't have one
                      </a>
                      <a href="#" onClick={() => setShowChangePassword(true)} className="d-block mt-2">
                        Forgot Password?
                      </a>
                      <a href="https://player.vimeo.com/video/981507363" className="d-block mt-2">
                        Login Tutorial
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                  <button
                  className="custom-button gray-button"
                  onClick={() => window.open('https://ariaslife.mykajabi.com/', '_blank')}
                >
                  New Agent Training Course
                </button>
              </div>
  

            </div>
  
            {/* Modals */}
            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Login Help</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'center' }}>
                <p>Your account may be accessed the day after receiving your agent number.</p>
                <p>Your username is the first letter of your first name + your full government last name.</p>
                <p>Example: Bernard Rapoport's username would be</p>
                <input type="text" value="BRAPOPORT" disabled style={{ display: '' }} />
                <p>Your default password is your most current agent number.</p>
              </Modal.Body>
            </Modal>
  
            <Modal show={showAriasModal} onHide={() => setShowAriasModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Important Notice</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'center' }}>
                <h1>The Agent Portal has undergone an update</h1>
                <h3>
                  The default usernames <strong>arias, mga, and big4</strong> will no longer work.
                </h3>
                <h5>Please log in using your personal agent account. Information on how to access this account is below.</h5>
                <p>Your account may be accessed the day after receiving your agent number.</p>
                <p>Your username is the first letter of your first name + your full government last name.</p>
                <p>Example: Bernard Rapoport's username would be</p>
                <input type="text" value="BRAPOPORT" disabled style={{ display: '' }} />
                <p>Your default password is your most current agent number.</p>
                <p>If you have any questions, please contact your MGA, or email us at account@ariaslife.com.</p>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  );
  
};

export default Login;
