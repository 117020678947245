import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './HierarchyTable.css'; // Import the CSS for styling
import { Modal, Button, Form } from 'react-bootstrap';
import TrophyCaseModal from '../Leaderboards/TrophyCaseModal'; // Ensure this path is correct based on your project structure

const HierarchyTable = () => {
    const [hierarchyData, setHierarchyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [email, setEmail] = useState('');
    const [showTrophyModal, setShowTrophyModal] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState(null);

    useEffect(() => {
        const fetchHierarchyData = async () => {
            try {
                // Retrieve userId from local storage
                const userId = localStorage.getItem('userId');

                // Check if userId is available
                if (!userId) {
                    setError('User ID not found in local storage');
                    setLoading(false);
                    return;
                }

                // Send userId to the backend
                const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', { userId });

                if (response.data.success) {
                    setHierarchyData(response.data.data);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Error fetching hierarchy data');
            } finally {
                setLoading(false);
            }
        };

        fetchHierarchyData();
    }, []);

    const handleActiveChange = async (id, newActiveStatus) => {
        try {
            const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/updateActiveStatus', {
                id,
                managerActive: newActiveStatus,
            });

            if (response.data.success) {
                setHierarchyData((prevData) =>
                    prevData.map((item) => (item.id === id ? { ...item, managerActive: newActiveStatus } : item))
                );
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error updating active status:', err);
            alert('Error updating active status');
        }
    };

    const openModal = (agent, agentNum) => {
        setModalData({ agent, agentNum });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalData({});
    };

    const openTrophyModal = (agentName) => {
        setSelectedAgentName(agentName);
        setShowTrophyModal(true);
    };

    const closeTrophyModal = () => {
        setShowTrophyModal(false);
        setSelectedAgentName(null);
    };

    if (loading) return <div>Loading...</div>; // Display "Loading..." while loading
    if (error) return <div>{error}</div>;

    const buildHierarchy = (data) => {
        const hierarchy = [];
        const map = {};
        const inactiveNodes = [];

        // Initialize map with each item
        data.forEach(item => {
            map[item.lagnname] = { ...item, children: [] };
        });

        // Group children under their respective parents based on the hierarchy rules
        data.forEach(item => {
            if (item.managerActive === 'n') {
                inactiveNodes.push(map[item.lagnname]);
                return;
            }

            if (item.sa && map[item.sa]) {
                map[item.sa].children.push(map[item.lagnname]);
            } else if (item.ga && map[item.ga]) {
                map[item.ga].children.push(map[item.lagnname]);
            } else if (item.mga && map[item.mga]) {
                map[item.mga].children.push(map[item.lagnname]);
            } else if (item.rga && map[item.rga]) {
                map[item.rga].children.push(map[item.lagnname]);
            } else {
                hierarchy.push(map[item.lagnname]);
            }
        });

        // Ensure the hierarchy follows the specified order
        const sortHierarchy = (nodes) => {
            return nodes.sort((a, b) => {
                const order = ['RGA', 'MGA', 'GA', 'AGT', 'SA'];
                const orderA = order.indexOf(a.clname);
                const orderB = order.indexOf(b.clname);

                // Custom order for AGT with null sa and non-null ga before SA with null sa and non-null ga
                if (orderA === orderB) {
                    if (a.clname === 'AGT' && b.clname === 'SA') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return -1;
                        }
                    } else if (a.clname === 'SA' && b.clname === 'AGT') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return 1;
                        }
                    }
                    return a.managerActive === 'n' ? 1 : -1;
                }

                return orderA - orderB;
            }).map(node => {
                node.children = sortHierarchy(node.children);
                return node;
            });
        };

        const sortedHierarchy = sortHierarchy(hierarchy);
        const sortedInactiveNodes = sortHierarchy(inactiveNodes);


        return { sortedHierarchy, sortedInactiveNodes };
    };

    const renderHierarchy = (nodes, isInactive = false) => {
        return nodes.map((node) => (
            <React.Fragment key={node.id}>
                <tr className={isInactive ? 'inactive-row' : ''}>
                    <td 
                        className="agent-name-link" 
                        onClick={() => openTrophyModal(node.lagnname)}
                    >
                        {node.lagnname}
                    </td>
                    <td style={{width: '40px !important'}}>{node.clname}</td>
                    <td>
                        <select
                            value={node.managerActive}
                            onChange={(e) => handleActiveChange(node.id, e.target.value)}
                        >
                            <option value="y">Yes</option>
                            <option value="n">No</option>
                        </select>
                    </td>
                    <td>
                        {node.redeemed === 1 ? (
                            'Yes'
                        ) : (
                            <button onClick={() => openModal(node.lagnname, node.agtnum)}>Send Email</button>
                        )}
                    </td>
                </tr>
                {renderHierarchy(node.children, isInactive)}
            </React.Fragment>
        ));
    };
    

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmitEmail = async () => {
        try {
            const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/send-account-email', {
                email: email,
                agentName: modalData.agent,
                agentNum: modalData.agentNum,
            });

            if (response.data.success) {
                alert('Email sent successfully');
                closeModal();
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error sending email:', err);
            alert('Error sending email');
        }
    };

    const { sortedHierarchy, sortedInactiveNodes } = buildHierarchy(hierarchyData);

    return (
        <>
            <table className='hierarchyTable'>
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th style={{width: '40px !important'}}>Contract</th>
                        <th>Active?</th>
                        <th>Redeemed</th>
                    </tr>
                </thead>
                <tbody>
                    {renderHierarchy(sortedHierarchy)}
                    {renderHierarchy(sortedInactiveNodes, true)}
                </tbody>
            </table>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Account Setup Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Agent: {modalData.agent}</p>
                        <p>Agent #: {modalData.agentNum}</p>
                        <Form>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </Form.Group>
                        </Form>
                        <Button
                            variant="primary"
                            onClick={handleSubmitEmail}
                            style={{
                                backgroundColor: '#00558c',
                                margin: '0 auto',
                                display: 'block',
                                marginTop: '20px',
                            }}
                        >
                            Submit
                        </Button>
                    </Modal.Body>
                </Modal>
            )}

            {showTrophyModal && (
                <TrophyCaseModal
                    agentName={selectedAgentName}
                    onClose={closeTrophyModal}
                />
            )}
        </>
    );
};

export default HierarchyTable;
