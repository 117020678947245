import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../../ActivityCards.css';
import axios from 'axios';
import AgentCountBadge from '../utils/AgentCountBadge';

const ActivityCards = ({ startDate, endDate, view, selectedAgent, setDateRange, isDateRangeHovered }) => {
    const [processedData, setProcessedData] = useState({
        alp: 0,
        calls: 0,
        appts: 0,
        sits: 0,
        sales: 0,
        refs: 0,
        callsToSet: 0,
        callsToSit: 0,
        showRatio: 0,
        closeRatio: 0,
        alpPerSit: 0,
        alpPerSale: 0,
        refsPerSit: 0,
        refSitPercent: 0,
        refClosePercent: 0,
        alpPerRefSale: 0,
        refAlp: 0,
        alpPerRefCol: 0,
        totalSales: 0,
    });

    const [displayData, setDisplayData] = useState({
        alp: 0,
        calls: 0,
        appts: 0,
        sits: 0,
        sales: 0,
        refs: 0,
        callsToSet: 0,
        callsToSit: 0,
        showRatio: 0,
        closeRatio: 0,
        alpPerSit: 0,
        alpPerSale: 0,
        refsPerSit: 0,
        refSitPercent: 0,
        refClosePercent: 0,
        alpPerRefSale: 0,
        refAlp: 0,
        alpPerRefCol: 0,
        totalSales: 0,
    });

    const [showAverage, setShowAverage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [teamData, setTeamData] = useState([]);
    const [agentCount, setAgentCount] = useState(0); // State for agent count
    const [agentNames, setAgentNames] = useState([]); // State for agent names
    const [agentData, setAgentData] = useState([]); // State for agent data

    const handleToggle = (event) => {
        setShowAverage(event.target.id === "average");
    };

    useEffect(() => {
        if (view === 'team') {
            fetchTeamData();
        } else if (view === 'myview') {
            fetchMyViewData();
        } else {
            fetchData();
        }
    }, [showAverage, startDate, endDate, view, selectedAgent]); // Add selectedAgent here

    useEffect(() => {
        if (view === 'team' && teamData.length > 0) {
            fetchData(teamData);
        }
    }, [teamData, view]);

    const fetchData = async (teamNames = teamData) => {
        setIsLoading(true);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];

        try {
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            const result = await response.json();
            if (result.success) {
                processData(result.data, formattedStartDate, formattedEndDate, teamNames);
            } else {
                console.error('Error fetching data:', result.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMyViewData = async () => {
        setIsLoading(true);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
    
        // Get agentName from localStorage if not passed in props
        const agentName = selectedAgent || localStorage.getItem('agnName'); // Use localStorage if selectedAgent is undefined
    
        try {
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            const result = await response.json();
            if (result.success) {
                processData(result.data, formattedStartDate, formattedEndDate, agentName);
            } else {
                console.error('Error fetching data:', result.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const fetchTeamData = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', {
                params: { userId }
            });
            const data = response.data;
            if (data.success && Array.isArray(data.data)) {
                const teamNames = data.data.flatMap(item => {
                    const agtString = item.AGTs;
                    const names = [];

                    // Extract the first part
                    const firstPart = agtString.split('; ')[0];
                    names.push(firstPart.split(' - ')[0]);

                    // Extract other parts
                    const otherParts = agtString.split('-- ');
                    otherParts.forEach(part => {
                        const name = part.split(' - ')[0];
                        names.push(name);
                    });

                    return names;
                });
                setTeamData(teamNames); // Set team names
            } else {
                console.error('Failed to load team data');
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const animateValue = (key, start, end, duration) => {
        if (start === end) return;
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setDisplayData(prevData => ({
                ...prevData,
                [key]: Math.floor(progress * (end - start) + start)
            }));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    };

    const processData = (data, formattedStartDate, formattedEndDate, agentName) => {
        let sum = {
            alp: 0,
            calls: 0,
            appts: 0,
            sits: 0,
            sales: 0,
            refs: 0,
        };
    
        const filteredData = data.filter(item => item.reportDate >= formattedStartDate && item.reportDate <= formattedEndDate);
    
        // Filter data based on view
        const dataToProcess = view === 'team'
            ? filteredData.filter(item => teamData.includes(item.agent))
            : view === 'myview'
            ? filteredData.filter(item => item.agent === agentName)
            : filteredData;
    
        setAgentCount(new Set(dataToProcess.map(item => item.agent)).size); // Set the agent count
        setAgentNames([...new Set(dataToProcess.map(item => item.agent))]); // Set the agent names
        setAgentData(dataToProcess); // Set the agent data
    
        dataToProcess.forEach(item => {
            sum.alp += parseFloat(item.alp || 0);
            sum.calls += parseFloat(item.calls || 0);
            sum.appts += parseFloat(item.appts || 0);
            sum.sits += parseFloat(item.sits || 0);
            sum.sales += parseFloat(item.sales || 0);
            sum.refs += parseFloat(item.refs || 0);
        });
    
        let result;
        if (showAverage && dataToProcess.length > 0) {
            result = {
                alp: sum.alp / dataToProcess.length,
                calls: sum.calls / dataToProcess.length,
                appts: sum.appts / dataToProcess.length,
                sits: sum.sits / dataToProcess.length,
                sales: sum.sales / dataToProcess.length,
                refs: sum.refs / dataToProcess.length,
            };
        } else {
            result = sum;
        }
    
        const calculatedStats = calculateStatistics(dataToProcess);
    
    
        setProcessedData({
            ...result,
            ...calculatedStats
        });
    

    
        // Animate value changes for all keys
        Object.keys(result).forEach(key => {
            animateValue(key, displayData[key], result[key], 500);
        });
        Object.keys(calculatedStats).forEach(key => {
            animateValue(key, displayData[key], calculatedStats[key], 500);
        });
    };
    
    

    const calculateStatistics = (data) => {
        const totalRefsCollected = data.reduce((sum, item) => sum + (parseFloat(item.refs) || 0), 0);
        const totalRefALP = data.reduce((sum, item) => sum + (parseFloat(item.refAlp) || 0), 0);
        const totalReferralSales = data.reduce((sum, item) => sum + (parseFloat(item.refSale) || 0), 0);
        const alpPerRefCol = totalRefsCollected > 0 ? totalRefALP / totalRefsCollected : 0;
        const calculatedRefALP = totalReferralSales > 0 ? totalRefALP / totalReferralSales : 0;
    
        return {
            callsToSet: calculateCallsToSet(data),
            callsToSit: calculateCallsToSit(data),
            showRatio: calculateShowRatio(data),
            closeRatio: calculateCloseRatio(data),
            alpPerSit: calculateALPPerSit(data),
            alpPerSale: calculateALPPerSale(data),
            refsPerSit: calculateRefsPerSit(data),
            refSitPercent: calculateRefSitPercent(data),
            refClosePercent: calculateRefClosePercent(data),
            alpPerRefSale: calculatedRefALP,
            refAlp: totalRefALP,
            alpPerRefCol: alpPerRefCol,
            totalSales: totalReferralSales,
        };
    };

    const calculateCallsToSet = (data) => {
        const totalCalls = data.reduce((sum, row) => sum + parseFloat(row.calls || 0), 0);
        const totalAppointments = data.reduce((sum, row) => sum + parseFloat(row.appts || 0), 0);
        return totalAppointments > 0 ? Math.round(totalCalls / totalAppointments) : 0;
    };

    const calculateCallsToSit = (data) => {
        const totalCalls = data.reduce((sum, row) => sum + parseFloat(row.calls || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        return totalSits > 0 ? Math.round(totalCalls / totalSits) : 0;
    };

    const calculateShowRatio = (data) => {
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        const totalAppointments = data.reduce((sum, row) => sum + parseFloat(row.appts || 0), 0);
        return totalAppointments > 0 ? ((totalSits / totalAppointments) * 100).toFixed(2) : '0.00';
    };
    
    const calculateCloseRatio = (data) => {
        const totalSales = data.reduce((sum, row) => sum + parseFloat(row.sales || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        return totalSits > 0 ? ((totalSales / totalSits) * 100).toFixed(0) : '0.00';
    };
    

    const calculateALPPerSit = (data) => {
        const totalALP = data.reduce((sum, row) => sum + parseFloat(row.alp || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        const alpPerSit = totalSits > 0 ? totalALP / totalSits : 0;
        return alpPerSit;
    };

    const calculateALPPerSale = (data) => {
        const totalALP = data.reduce((sum, row) => sum + parseFloat(row.alp || 0), 0);
        const totalSales = data.reduce((sum, row) => sum + parseFloat(row.sales || 0), 0);
        const alpPerSale = totalSales > 0 ? totalALP / totalSales : 0;
        return alpPerSale;
    };

    const calculateRefsPerSit = (data) => {
        const totalRefsCollected = data.reduce((sum, row) => sum + parseFloat(row.refs || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        return totalSits > 0 ? (totalRefsCollected / totalSits).toFixed(2) : 0;
    };

    const calculateRefSitPercent = (data) => {
        const totalRefSits = data.reduce((sum, row) => sum + parseFloat(row.refSit || 0), 0);
        const totalRefsCollected = data.reduce((sum, row) => sum + parseFloat(row.refs || 0), 0);
        return totalRefsCollected > 0 ? ((totalRefSits / totalRefsCollected) * 100).toFixed(2) : '0.00';
    };
    
    const calculateRefClosePercent = (data) => {
        const totalReferralSits = data.reduce((sum, row) => sum + parseFloat(row.refSit || 0), 0);
        const totalReferralSales = data.reduce((sum, row) => sum + parseFloat(row.refSale || 0), 0);
        return totalReferralSits > 0 ? ((totalReferralSales / totalReferralSits) * 100).toFixed(2) : '0.00';
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }).format(value);
    };

    const formatDateRange = (start, end) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedStartDate = start.toLocaleDateString('en-US', options);
        const formattedEndDate = end.toLocaleDateString('en-US', options);
        return `${formattedStartDate} - ${formattedEndDate}`;
    };
    const dateRange = formatDateRange(startDate, endDate);

    return (
        <>
            <div className="header-row">
                <h4>Activity</h4>
                {(view === 'team' || view === 'agency') && (
                    <AgentCountBadge 
                        count={agentCount} 
                        agentNames={agentNames} 
                        agentData={agentData} 
                        dateRange={dateRange} // Pass the date range prop
                        onDateRangeChange={(newRange) => setDateRange(newRange)} // Add this callback to update the date range
                        startDate={startDate} // Pass the start date prop
                        endDate={endDate} // Pass the end date prop
                        
                    />
                )}
            </div>
            <div className={`cards-row ${isDateRangeHovered ? 'hovered' : ''}`}>
                <div className="card">
                    <h5 className="card-title">ALP</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.alp)}</p>}
                </div>
                <div className="card">
                    <h5 className="card-title">Calls</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.calls.toLocaleString()}</p>}
                </div>
                <div className="card">
                    <h5 className="card-title">Appts</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.appts.toLocaleString()}</p>}
                </div>
                <div className="card">
                    <h5 className="card-title">Sits</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.sits.toLocaleString()}</p>}
                </div>
                <div className="card">
                    <h5 className="card-title">Sales</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.sales.toLocaleString()}</p>}
                </div>
                <div className="card">
                    <h5 className="card-title">Refs Collected</h5>
                    {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.refs.toLocaleString()}</p>}
                </div>
            </div>
            <div style={{ marginTop: '0px' }}>
                <div className="header-with-toggle" style={{ marginBottom: '20px', marginTop: '10px' }}>
                    <h4>Statistics</h4>
                </div>
                <div className={`cards-row ${isDateRangeHovered ? 'hovered' : ''}`}>
                    <div className="card">
                        <h5 className="card-title">Calls to Set</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.callsToSet}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Calls to Sit</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.callsToSit}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Show Ratio</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.showRatio}%</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Close Ratio</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.closeRatio}%</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">ALP/Sit</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.alpPerSit)}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">ALP/Sale</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.alpPerSale)}</p>}
                    </div>
                </div>
                <div className="header-with-toggle" style={{ marginBottom: '20px', marginTop: '10px' }}>
                    <h4>Ref Stats</h4>
                </div>
                <div className={`cards-row ${isDateRangeHovered ? 'hovered' : ''}`}>
                    <div className="card">
                        <h5 className="card-title">Ref ALP</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.refAlp)}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">ALP/Ref Col</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.alpPerRefCol)}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">ALP/Ref Sale</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{formatCurrency(displayData.alpPerRefSale)}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Refs/Sit</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.refsPerSit}</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Ref Sit%</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.refSitPercent}%</p>}
                    </div>
                    <div className="card">
                        <h5 className="card-title">Ref Close%</h5>
                        {isLoading ? <TailSpin height="20" width="20" color="gray" ariaLabel="loading" /> : <p className="card-text">{displayData.refClosePercent}%</p>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivityCards;

