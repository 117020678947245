import React from 'react';

const LeaderboardChild = ({ title, rows }) => {
    // Function to format numbers as currency
    const formatCurrency = (value) => {
        if (!value) return "N/A";
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    // Determine the column name based on the title
    const columnName = title === 'SA Data' ? 'SA' : title === 'GA Data' ? 'GA' : 'Agent';

    return (
        <div>
            <h2>{title}</h2>
            <div className={`leaderboard-child ${title === "All Data" ? "all-data" : ""}`}>


                {rows.length > 0 ? (
                    <table className="all-leaderboard-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{columnName}</th>
                                <th>ALP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            {row.profpic ? (
                                                <img
                                                    src={row.profpic}
                                                    alt={row.reformattedName || "Agent Profile"}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#ddd",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginBottom: "5px",
                                                        fontWeight: "bold",
                                                        color: "#666",
                                                    }}
                                                >
                                                    {row.reformattedName?.charAt(0).toUpperCase() || "N"}
                                                </div>
                                            )}
                                            <strong
                                                style={{
                                                    fontSize: "14px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {row.reformattedName}
                                            </strong>
                                            {title !== "Top MGAs" && title !== "Top RGAs" && (
                                                <small
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "12px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {row.MGA_NAME?.split(" ")[0] || "N/A"}
                                                </small>
                                            )}
                                            {title === "All Data" && (
                                                    <span
                                                        className="user-role-badge"
                                                        style={{
                                                            backgroundColor:
                                                                row.clname === 'AGT'
                                                                    ? 'lightgrey'
                                                                    : row.clname === 'SA'
                                                                    ? 'rgb(178, 82, 113)'
                                                                    : row.clname === 'GA'
                                                                    ? 'rgb(237, 114, 47)'
                                                                    : row.clname === 'MGA'
                                                                    ? 'rgb(104, 182, 117)'
                                                                    : row.clname === 'RGA'
                                                                    ? '#00558c'
                                                                    : 'transparent',
                                                            border: `2px solid ${
                                                                row.clname === 'AGT'
                                                                    ? 'grey'
                                                                    : row.clname === 'SA'
                                                                    ? 'rgb(138, 62, 93)'
                                                                    : row.clname === 'GA'
                                                                    ? 'rgb(197, 94, 37)'
                                                                    : row.clname === 'MGA'
                                                                    ? 'rgb(84, 152, 97)'
                                                                    : row.clname === 'RGA'
                                                                    ? '#004372'
                                                                    : 'transparent'
                                                            }`,
                                                            padding: "2px 4px",
                                                            borderRadius: "4px",
                                                            fontSize: "10px",
                                                        }}
                                                    >
                                                        {row.clname}
                                                    </span>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ padding: "0", width: "0", height: "0" }}></td>
                                    <td>{formatCurrency(row.alpValue)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available for this leaderboard.</p>
                )}
            </div>
        </div>
    );
};

export default LeaderboardChild;


