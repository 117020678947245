import React, { useState, useEffect } from "react";
import "./Checklist.css";
import { FaLock, FaLockOpen, FaDownload } from "react-icons/fa";
import RoleplayModal from "./RoleplayModal";
import { useProgress } from "./ProgressContext"; // Import useProgress hook

const Checklist = () => {
  const [responses, setResponses] = useState({});
  const { progress, setProgress } = useProgress(); // Use context for progress
  const [countdown, setCountdown] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // Changed state name to "isModalOpen" for clarity
  const [activeModalContent, setActiveModalContent] = useState("");
  const [isReleaseScheduled, setIsReleaseScheduled] = useState(false);
  const [onReleaseList, setOnReleaseList] = useState(false);
  const [releaseScheduledDate, setReleaseScheduledDate] = useState(null);
  const [isReleased, setIsReleased] = useState(false);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [agents, setAgents] = useState([]); // New state to store agents
  const [selectedAgent, setSelectedAgent] = useState(""); // State for selected agent
  const [reviewerOptions, setReviewerOptions] = useState([]); // New state for "Reviewed By" dropdown
  const [totalRowsThisWeek, setTotalRowsThisWeek] = useState(0); // New state to track rows count this week

  const openModal = (content) => {
    // Check if content has a specific modal content from roleplayContent
    const modalContent = roleplayContent[content] || content;
    setActiveModalContent(modalContent);
    setIsModalOpen(true);
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModalContent("");
  };
// Calculate next Friday midnight in EST (resets every Friday at 12:01 AM EST)
const getNextFridayMidnight = () => {
  const now = new Date();
  const dayOfWeek = now.getUTCDay();
  const daysUntilFriday = (6 - dayOfWeek + 7) % 7 || 7; // Days until next Friday
  const nextFridayMidnightUTC = new Date(now);
  
  nextFridayMidnightUTC.setUTCDate(now.getUTCDate() + daysUntilFriday);
  nextFridayMidnightUTC.setUTCHours(5, 1, 0, 0); // 12:01 AM EST is 5:01 AM UTC

  return nextFridayMidnightUTC;
};
const getCurrentWeekRange = () => {
  const now = new Date();
  const currentDay = now.getDay();

  // Calculate the start of the week as the previous Saturday (midnight EST)
  const weekStart = new Date(now);
  weekStart.setDate(now.getDate() - ((currentDay + 1) % 7)); // Adjust to the previous Saturday
  weekStart.setUTCHours(5, 0, 0, 0); // Midnight EST (5:00 UTC on Saturday)

  // Calculate the end of the week as Friday at 11:59:59 PM EST
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6); // Move to the following Friday
  weekEnd.setUTCHours(23, 59, 59, 999); // 11:59:59 PM EST on Friday

  return { weekStart, weekEnd };
};


const getNextMonday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilMonday = (8 - dayOfWeek) % 7 || 7; // Days until next Monday
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilMonday);

  // Format the date to MM/DD/YY
  const month = String(nextMonday.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(nextMonday.getDate()).padStart(2, '0');
  const year = String(nextMonday.getFullYear()).slice(-2); // Get last two digits of the year

  return `${month}/${day}/${year}`;
};
useEffect(() => {
  if (userRole !== "AGT") {
    const fetchAgents = async () => {
      try {
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId }), // Ensure userId is sent in the request body
        });

        const data = await response.json();

        if (data.success) {
          console.log("Agents data:", data.data);
          setAgents(data.data); // Update state with agents data
        } else {
          console.error("Failed to fetch agents list:", data.message);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
      }
    };

    fetchAgents();
  }
}, [userRole, userId]);

const handleAgentSelection = async (event) => {
  const selectedId = event.target.value;

  if (!selectedId) {
    console.warn("No agent selected.");
    return;
  }

  const selectedAgentData = agents.find(agent => agent.id === parseInt(selectedId, 10));

  if (!selectedAgentData) {
    console.error("Selected agent data not found.");
    return;
  }

  // Reset onReleaseList to false initially
  setOnReleaseList(false);

  setSelectedAgent(selectedAgentData);
  setReviewerOptions(
    [selectedAgentData.sa, selectedAgentData.ga, selectedAgentData.mga, selectedAgentData.rga].filter(Boolean)
  ); // Filter out null or empty values
  
  // Fetch checklist data for the selected agent
  fetchChecklistData(selectedAgentData.id);
  
  // Check if the selected agent is on the release list
  const releaseStatus = await checkReleaseScheduled(selectedAgentData.id);
  setOnReleaseList(releaseStatus.success); // Update onReleaseList based on the agent's release status
};

const checkReleaseScheduled = async (agentId) => {
  const userIdToUse = agentId || localStorage.getItem("userId"); // Use agentId if available; otherwise, use userId from local storage

  try {
    const response = await fetch(
      `https://ariaslogin-4a95935f6093.herokuapp.com/api/check-release-scheduled?userId=${userIdToUse}`
    );
    const result = await response.json();
    console.log("Release schedule data:", result);

    // Log today's date in UTC
    const today = new Date();
    console.log("Today's date (UTC):", today.toISOString());

    // Calculate and log the week range
    const { weekStart, weekEnd } = getCurrentWeekRange();
    console.log("Week start (UTC):", weekStart.toISOString());
    console.log("Week end (UTC):", weekEnd.toISOString());

    // Set the initial release schedule status and date
    setIsReleaseScheduled(result.isScheduled);
    setReleaseScheduledDate(result.release_scheduled);

    // Check if allRowsResult is present
    if (result.allRowsResult) {
      const rowsThisWeek = result.allRowsResult.filter((row) => {
        const submissionDate = new Date(row.time_submitted);
        const isWithinRange =
          submissionDate >= weekStart &&
          submissionDate <= weekEnd &&
          row.release_scheduled === null; // Exclude rows with a non-null release_scheduled

        // Log each row's submission date and check if it's within the range
        console.log(
          `Row time_submitted (UTC): ${submissionDate.toISOString()}, Within range: ${isWithinRange}`
        );

        return isWithinRange;
      });

      // Update the state with the count for this week
      setTotalRowsThisWeek(rowsThisWeek.length);
      console.log("Total rows this week:", rowsThisWeek.length);
    } else {
      console.warn("allRowsResult is not defined in the response");
    }

    return result;
  } catch (error) {
    console.error("Error checking release schedule status:", error);
    return { success: false };
  }
};


useEffect(() => {
  // Fetch release status for logged-in user if they are not AGT
  const fetchReleaseStatus = async () => {
    if (userRole !== "AGT") {
      const agentId = selectedAgent?.id || userId; // Use selectedAgent if available, fallback to userId
      await checkReleaseScheduled(agentId);
    }
  };

  fetchReleaseStatus();
}, [userId, selectedAgent, userRole]); // Dependencies include userRole to re-check when role changes




useEffect(() => {
  if (selectedAgent) {
    fetchChecklistData(selectedAgent.id); // Fetch checklist for selected agent
  }
}, [selectedAgent]);


useEffect(() => {
  const checkReleased = async () => {
    try {
      const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/check-released?userId=${userId}`);
      const result = await response.json();

      if (result.success) {
        setIsReleased(result.isReleased);
      } else {
        console.error("Failed to check release status");
      }
    } catch (error) {
      console.error("Error fetching release status:", error);
    }
  };

  checkReleased();
}, [userId]);

// Initialize countdown timer
useEffect(() => {
  const targetTime = getNextFridayMidnight();

  const updateCountdown = () => {
    const now = new Date();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      setCountdown("Time's up!");
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDifference / 1000) % 60);

    // Display days only if more than zero
    setCountdown(`${days > 0 ? `${days}d ` : ""}${hours}h ${minutes}m ${seconds}s`);
  };

  const timer = setInterval(updateCountdown, 1000);

  // Cleanup timer on unmount
  return () => clearInterval(timer);
}, []);







  // Define sections with shortened keys
  const sections = {
    Preparation: {
      arias_training: "*Arias Organization Optional Training Completed",
      booking_done: "*Phones Video Filmed and Reviewed",
      video_done: "*Final Video Filmed and Reviewed",
      leadership_track: "*Understand Leadership Track",
      sale_1k: "*At least 1 sale, $1,000 ALP",
      practice_pres: "*10 Practice Presentations",
      refs_25: "*25 Refs Collected in Impact",
      build_team: "*Do you want to build a team?",
      know_team: "*Do you know how to build a team?",
      contract_2nd: "How long to activate your second contract?",
      bonus_90d: "How much is the recruiting bonus? (first 90 days)",
      bonus_after_90d: "How much is the recruiting bonus? (after 90 days)",
      ready_release: "*Do you feel ready to be released at this point?",
      know_more: "What would you like to know more about at this point?",
    },
    RolePlay: {
      entrance_start: "*Entrance From Beginning",
      referral_open: "*Referral Opening",
      texting_referral: "*Texting/Setting Referral from Home",
      closing_rebuttals: "*Closing & 2 Rebuttals",
      personal_recruit: "*Personal Recruiting",
    },
    VideoReview: {
      reviewed_by: "Reviewed By",
      on_script: "*On Script",
      warmup_conf: "*Effective Confident Warm-Up",
      create_need: "*Strong Ability to Create Need",
      sale_cemented: "*Cemented the Sale",
      would_sell: "*Would the Person Sell You",
    },
    Numbers: {
      ride_days_trainee: "Ride Days (Trainee)",
      ride_days_trainer: "Ride Days (Trainer)",
      pres_done_trainee: "Presentations Done (Trainee)",
      pres_done_trainer: "Presentations Done (Trainer)",
      ref_pres_done_trainee: "Referral Presentations Done (Trainee)",
      ref_pres_done_trainer: "Referral Presentations Done (Trainer)",
      ref_sold_trainee: "Referrals Sold (Trainee)",
      ref_sold_trainer: "Referrals Sold (Trainer)",
      ref_collected_trainee: "Referrals Collected (Trainee)",
      ref_collected_trainer: "Referrals Collected (Trainer)",
      sales_done_trainee: "Sales Made (Trainee)",
      sales_done_trainer: "Sales Made (Trainer)",
      alp_written_trainee: "ALP Written (Trainee)",
      alp_written_trainer: "ALP Written (Trainer)",
      appts_set_trainee: "Appointments Set (Trainee)",
      appts_set_trainer: "Appointments Set (Trainer)",
      recruits_trainee: "Recruits Collected (Trainee)",
      recruits_trainer: "Recruits Collected (Trainer)",
    },
    Expectations: {
      appts_weekly: "Weekly Appointments Set",
      pres_weekly: "Weekly Presentations",
      refs_per_home: "Referrals Collected Per Home",
      alp_week: "ALP Per Week",
      start_wkdy: "What time do you start? (Weekdays)",
      start_wknd: "What time do you start? (Weekends)",
    },
  };

  const correctAnswers = {
    contract_2nd: "45",        // Expected correct answer for "contract_2nd"
    bonus_90d: "750",          // Expected correct answer for "bonus_90d"
    bonus_after_90d: "250",    // Expected correct answer for "bonus_after_90d"
  };
  
  // Function to determine the border color based on the value
const getBorderColor = (key) => {
  if (responses[key] === correctAnswers[key]) {
    return "lightgreen"; // Correct answer
  } else if (responses[key] && responses[key] !== correctAnswers[key]) {
    return "red"; // Incorrect answer
  }
  return ""; // Default border if the input is empty
};
  // Define the keys to include in progress calculation
  const progressKeys = [
    ...Object.keys(sections.Preparation),
    ...Object.keys(sections.RolePlay),
    ...Object.keys(sections.VideoReview),
  ];
  
  // Exclude optional fields from progress calculation
  const excludeKeys = ["know_more", "reviewed_by"];
  const filteredProgressKeys = progressKeys.filter((key) => !excludeKeys.includes(key));
  
  // Total items in Preparation section with increments for practice_pres and refs_25
  const totalProgressItems = filteredProgressKeys.length + 10 + 25; // Add 10 for practice_pres and 25 for refs_25
  
  const roleplayContent = {
    "entrance_start": (
      <>
        <h2>Group Benefits Review</h2>
        <p>Hi, Joe! Can you hear and see me okay? Alright, awesome!</p>
        <p>Build rapport using F.O.R.M. 1-2 minutes</p>
  
        <ul>
          <li><strong>Family:</strong> I see that you live in CITY, how long have you lived there? That's great! Do you still live nearby the family? Okay, nice!</li>
          <li><strong>Occupation:</strong> Joe, what do you do for your job? How do you like doing that? Do you work with a lot of people? Getting any overtime?</li>
          <li><strong>Recreation:</strong> So, when you're not working so hard, what do you like to do for fun? Camping, hunting, hiking?</li>
          <li><strong>Me:</strong> Relate to them any way that you can and show interest in the member.</li>
        </ul>
  
        <p>Like I mentioned on the phone, my name is Justin and I'll be explaining a few of the benefits being provided through American Income Life for the members of GROUP. Feel free to take some notes because this is very important. Sound good?</p>
  
        <h2>Union Representation</h2>
        <p>Joe, do you get very involved with GROUP? Do you go to your meetings? Ok, no problem, that's exactly why I'm here. Let's take a step back so I can fill you in on what's going on. American Income Life Insurance Company is a division of Globe Life and provides permanent insurance benefits to members of the major unions, associations, and groups, like your GROUP.</p>
        <p>In fact, here is the Labor Advisory Board REFER TO SLIDE. It consists of the top international labor and association leaders from all across North America. Their job is to find the gaps in your current benefits and then fill those gaps with the best possible benefits for members like you. They did all the research and found that American Income was the best option for their members.</p>
        <p>As you know, we have benefits set up through work, but the problem is, as soon as we retire, change jobs or lose our job, what happens to all of those benefits? That's right! We typically lose them because they usually aren't permanent. The good news is, since you're a member of GROUP, they're making sure that you're set up with permanent benefits TODAY that can stay with you FOREVER.</p>
  
        <p>Here's a copy of the letter you received from GROUP OPEN PREAPPROACH LETTER.</p>
  
        <h2>Two Part Program</h2>
        <ul>
          <li>First, I'll be explaining a few of the benefits being provided for the members of GROUP.</li>
          <li>Second, the most important reason that we're meeting, I’ll educate you on some of the additional benefits available to the members and make sure you qualify so we can set up your benefit package TODAY.</li>
        </ul>
      </>
    ),
    "referral_open": (
      <>
        <h2>Sponsorship Program:</h2>
        <p>Hi, Joe! Can you hear and see me okay? How's your day going so far? Do you have a spouse or significant other?</p>
        <p>Build rapport using F.O.R.M. 1-2 minutes</p>
  
        <ul>
          <li><strong>Family:</strong> I see that you live in CITY, how long have you lived there? That's great! Do you still live nearby the family? Okay, nice!</li>
          <li><strong>Occupation:</strong> Joe, what do you do for your job? How do you like doing that? Do you work with a lot of people? Getting any overtime?</li>
          <li><strong>Recreation:</strong> So, when you're not working so hard, what do you like to do for fun? Camping, hunting, hiking?</li>
          <li><strong>Me:</strong> Relate to them any way that you can and show interest in the member.</li>
        </ul>
<p>
Like I mentioned on the phone, my name is AGENT and I'll be explaining the benefits that were set up for you by SPONSOR. Feel free to take some notes because this is very important, and we're only able to go over this one time. Sound good?
</p>


<h4>Sponsorship Program:</h4>
<p>Did you talk with SPONSOR to find out why we're meeting and what they set up for your family? </p>
<p>(Yes or No) Ok, I will back up and fill you in on what is going on.</p>
<p>SPONSOR is a member in good standing with American and was allowed to extend some of their benefits to a few close friends and family members. SPONSOR knew these benefits would be perfect for you and your family so they wanted me to take care of you right away. We also take care of the benefits for all the unions, like the police, firefighters, teachers, and veterans.</p>
<p>As you know, we have benefits set up through work, but the problem is, as soon as we retire, change jobs or lose our job, what happens to all of those benefits? That's right! We typically lose them because they usually aren't permanent. Our company, American Income, started to realize that too many families have no benefits at all, or only temporary benefits through work. The good news is, they teamed up with the unions to make sure that you're set up with permanent benefits TODAY that stay with you FOREVER. Does that make sense?</p>
<h2>Two Part Program</h2>
<p>My job is to meet with you for 2 reasons:</p>
<ul>
          <li>First, I'll explain your benefits package that SPONSOR set up for your family.
          </li>
          <li>Second, the most important reason that we're meeting, is to help you qualify for the permanent union benefits that SPONSOR made available to you. Now, just because some of the people that you know got in, that doesn't mean you're automatically allowed to enroll. These are permanent benefits that you get to keep for the rest of your life. But you do have to qualify.</li>
        </ul>
  </>
  ),

  "texting_referral": (
    <>
    <h4>Sponsorship Program:</h4>
<p>Now Joe, you're allowed to extend some of these benefits to the people you're closest with. They'll get Child Safe kits for their children. They'll get their own membership for AIL Plus and will be able to save all kinds of money for their family. And they'll get a NO COST Will Kit to take care of their family. Not only that, but they'll also get a $2,000 AD&D certificate that works exactly like what you have through the GROUP.</p>
<p>So Joe, we'll take care of your family first, then your close friends. Let's start with your family. Who are you closest to in your family?</p>

<p>Collect names:</p>
<ul>
  <li>What's their first name?</li>
  <li>Last name?</li>
  <li>Relationship?</li>
</ul>
<p>If they can't think of many Most people open their contacts and scroll until they see a close friend or family member. So, who's the first person that you see in your contacts?</p>
<p>Who's your next closest family member/friend? Repeat until they run out of names THEN collect #s.</p>

<h4>Collect contact info:</h4>
<ul>
  <li>What's a good phone # for first ref that you collected?</li>
  <li>Where are they from? City/State</li>
  <li>What's the name of their spouse/SO?</li>
</ul>

<h4>REFERRAL REBUTTALS</h4>

<p><strong>Why do you need to know their phone #?</strong></p>
<p>That’s a good question. The phone # is how we get the benefits out to them, so we definitely need that. What’s a good phone # for them?</p>
<p><strong>We still don’t like giving out #s.</strong></p>
<p>You know what Joe, I’m the exact same way! I usually don’t like giving out my friend’s/family’s information. A few other people said the same thing. However, when you consider the fact that this could save the life of a missing child, help your family save money and be there when they need it most, it becomes one of those things that is just too important to not help out with. What’s a good phone # for them?</p>

<h4>TEXTING REFERRALS:</h4>
<p>Now Joe, it is very important that you let the people you sponsored know who I am. So I am going to send a group text with you and each person you sponsored to introduce myself and let them know what you’ve done for them. When you receive it, can you please reply “Thank you AGENT", so they know that we are working together to get this out to them? Ok, go ahead and reply to each one. I will wait until I see it on my side.</p>
<p>CONFIRM ALL REFERRALS HAVE INDIVIDUALLY BEEN TEXTED</p>


<h5>TEXT TEMPLATE</h5>
<p>Hello, my name is AGENT. I'm the benefits rep for Joe. They were able to extend some important benefits for your family, from their GROUP. It doesn't take long but it is very important. What time are you free so I can give you a quick call?</p>

</>
  ),
  "closing_rebuttals": (
    <>
    <h4>Close</h4>
    <p>My job is to help you get enrolled into the benefits. Joe, let's face it, we're all going to die, we don't have a choice. Either we take care of this now, or someone in your immediate family (brother, sister, child) will be forced to deal with this later when something happens. Does that make sense?</p>

<p>Joe, most families try to qualify for the RECOMMENDED plan which is based on you setting aside just $/week. But then some families just get their foot in the door with the BASIC plan, knowing they have the option to upgrade down the road. Here's what the BASIC plan looks like for only $/wk: Explain BASIC plan</p>

<p>So, Joe, which plan do you choose to protect your family, the BASIC plan or the RECOMMENDED plan? Look at camera lens and maintain eye contact.</p>

<h4>Rebuttals</h4>

<p><strong>“I need to think about it.”</strong></p>
<p>That's not a problem. I understand how you feel. But when you think about it, there are only three times when we actually think about life insurance.</p>
<p>When somebody close to us dies.</p>
<p>When we have a near death experience.</p>
<p>When I show up and I kinda force you to think about it.</p>
<p>The truth is, you probably weren't thinking about this before I got here and you won't think about it when I leave. It will go on the backburner like a lot of other things. However, it will remain a PROBLEM that needs to be fixed. The only way we can fix this PROBLEM is by getting your program started today, BUT KEEPING IT COMFORTABLE! Most members are enrolling at the RECOMMENDED plan, but then some members are going with the BASIC plan just to get their foot in the door. Which one works best for you?</p>

<p><strong>“I can't afford it.”</strong></p>
<p>I understand where you're at. It's tough to make ends meet. It seems like as soon as we get caught up, something else puts us back in the hole. But over the years, you've always found a way to provide for your family. When you die you won't be here to provide for your family. This program WILL BE here for them. The most important thing is to get started but keep it comfortable.</p>
<p>So, let me ask. Which part did you like the best, the Accidental Hospital Benefits, Final Expense Protection, Income Protection, Accidental Death? Okay, we're going to adjust it so that you still have a lot of coverage but it's more afforable. Adjust Whole Life and 10RC, trying to keep more of what they like, until the premium lowers by about 25% Looks like we got it down to only $/mo, sound good?</p>

</>
  ),

  "personal_recruit": (
    <>
    <h4>Personal Recruiting</h4>
    <p>Intro: So, is this kind of an in-betweener job or are you planning on doing this forever?</p>
    <p>Line 1: I'm a consultant in the fin services industry.</p>
    <p>Line 2: We use insurance products to protect people's assets.</p>
    <p>Line 3: Cool thing is we make a lot of money and have fun doing it and we are helping people out. Do you like helping people out?</p>
    <p>Line 4: Give me your number and I will have the hiring manager call you the next time we are hiring.</p>
    </>
  )
  };
  

  const calculateProgress = () => {
    let completedItems = filteredProgressKeys.filter((key) => {
      // Check specific fields against the correct answers
      if (key === "contract_2nd") {
        return responses[key] === correctAnswers[key];
      }
      if (key === "bonus_90d") {
        return responses[key] === correctAnswers[key];
      }
      if (key === "bonus_after_90d") {
        return responses[key] === correctAnswers[key];
      }
  
      // For other items, simply check if they are completed (true or have a value)
      return responses[key];
    }).length;
  
    // Add progress for practice presentations and refs collected
    completedItems += Math.min(responses.practice_pres || 0, 10);
    completedItems += Math.min(responses.refs_25 || 0, 25);
  
    // Set the progress percentage
    setProgress((completedItems / totalProgressItems) * 100);
  };
  
  // Re-run the calculation whenever `responses` changes
  useEffect(() => {
    calculateProgress();
  }, [responses]);
  
  
  const fetchChecklistData = async (agentId) => {
    try {
      const response = await fetch(
        `https://ariaslogin-4a95935f6093.herokuapp.com/api/get-checklist?userId=${agentId || userId}`
      );
      const data = await response.json();
  
      if (data.success && data.checklist) {
        setResponses(data.checklist); // Load existing checklist data
        setIsReleased(data.checklist.released === 1); // Check and set released status for AGT
        console.log("Checklist data loaded:", data.checklist);
      } else {
        console.warn("Checklist not found for the provided userId, initializing empty checklist");
        setResponses({}); // Initialize with an empty object if no checklist exists
      }
    } catch (error) {
      console.error("Error fetching checklist data:", error);
      setResponses({}); // Ensure responses are empty if there’s an error
    }
  };
  
  
  useEffect(() => {
    if (userRole === "AGT") {
      fetchChecklistData(userId); // Fetch data for AGT role using userId
    } else if (selectedAgent) {
      fetchChecklistData(selectedAgent.id); // Fetch data for selected agent
    }
  }, [userRole, selectedAgent]);
  

  
  const updateProgress = async (updates) => {
    const agentId = selectedAgent?.id || userId; // Use selected agent ID or default to logged-in user
    try {
      const response = await fetch(
        "https://ariaslogin-4a95935f6093.herokuapp.com/api/update-progress",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: agentId, updates }), // Use selected agent ID
        }
      );
      const result = await response.json();
      if (!result.success) throw new Error(result.message);
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };
  const handleCheckboxChange = (key) => {
    const updatedResponses = { ...responses, [key]: !responses[key] };
    setResponses(updatedResponses);
    updateProgress({ [key]: updatedResponses[key] });
  };

  const handleInputChange = (key, value) => {
    // Keep the existing behavior of updating `responses` state
    const newResponses = { ...responses, [key]: value };
    setResponses(newResponses);
  
    // Add immediate update for specific fields in Expectations section
    if (["start_wkdy", "start_wknd"].includes(key)) {
      updateProgress({ [key]: value });
    }
  
    // Preserve the current functionality (if there are any other conditions in your existing setup)
    // Example: If there are other fields requiring specific behavior, add those below
    if (["practice_pres", "refs_25", "build_team", "know_team", "reviewed_by"].includes(key) || key.includes("trainee") || key.includes("trainer")) {
      updateProgress({ [key]: value });
    }
  };
  
  
  
  
// Set defaults in the Numbers section to 0
useEffect(() => {
  const defaults = {
      ...responses,
      Numbers: {
          ride_days_trainee: 0, ride_days_trainer: 0,
          pres_done_trainee: 0, pres_done_trainer: 0,
          ref_pres_done_trainee: 0, ref_pres_done_trainer: 0,
          ref_sold_trainee: 0, ref_sold_trainer: 0,
          ref_collected_trainee: 0, ref_collected_trainer: 0,
          sales_done_trainee: 0, sales_done_trainer: 0,
          alp_written_trainee: 0, alp_written_trainer: 0,
          appts_set_trainee: 0, appts_set_trainer: 0,
          recruits_trainee: 0, recruits_trainer: 0,
      }
  };
  setResponses(defaults);
}, []);

  
const handleInputBlur = (key) => {
  // Add existing blur functionality here if any
  
  // Add update for the Expectations fields on blur
  if (["start_wkdy", "start_wknd", "appts_weekly", "pres_weekly", "refs_per_home", "alp_week"].includes(key)) {
    updateProgress({ [key]: responses[key] });
  }

  // Preserve the existing functionality (e.g., if any specific behavior for other fields on blur)
  if (["contract_2nd", "bonus_90d", "bonus_after_90d"].includes(key)) {
    updateProgress({ [key]: responses[key] });
  }
};

  

const handleScheduleReleaseCall = async () => {
  // Use selected agent ID if an agent is selected, otherwise use logged-in user ID
  const agentId = selectedAgent?.id || userId;

  try {
    const response = await fetch("https://ariaslogin-4a95935f6093.herokuapp.com/api/schedule-release", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: agentId }), // Send selected agent ID
    });
    const result = await response.json();

    if (result.success) {
      // Immediately update the UI state
      setIsReleaseScheduled(true);
      setReleaseScheduledDate(result.release_scheduled); // Use the returned scheduled date
      setOnReleaseList(true); // Update onReleaseList since the release was successfully scheduled
    } else {
      console.error(result.message);
    }
  } catch (error) {
    console.error("Error scheduling release call:", error);
  }
};

  
  useEffect(() => {
    const checkReleaseScheduled = async () => {
      // Use selected agent ID if an agent is selected, otherwise use logged-in user ID
      const agentId = selectedAgent?.id || userId;
  
      try {
        const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/check-release-scheduled?userId=${agentId}`);
        const result = await response.json();
        console.log('release', result);
        setIsReleaseScheduled(result.isScheduled);
        setReleaseScheduledDate(result.release_scheduled); // Save the release_scheduled date
        if (result.success===true) {
          setOnReleaseList(true);
        }
      } catch (error) {
        console.error("Error checking release schedule status:", error);
      }
    };
  
    checkReleaseScheduled();
  }, [userId, selectedAgent]); // Re-run if selectedAgent changes
  

  useEffect(() => {
    calculateProgress();
  }, [responses]);

  return (
    <div className="checklist-container">


<div className="release-top-bar">
  {userRole !== "AGT" && (
    <>
      <div className="release-agent-selection">
        {agents.length > 0 && (
          <>
            <label htmlFor="agentDropdown">Select an Agent:</label>
            <select
              id="agentDropdown"
              value={selectedAgent?.id || ""}
              onChange={handleAgentSelection}
            >
              <option value="">Select Agent</option>
              {agents
                .filter((agent) => agent.released === 0)
                .map((agent) => (
                  <option key={agent.id} value={agent.id}>
                    {agent.lagnname}
                  </option>
                ))}
            </select>
          </>
        )}
      </div>

      {/* Countdown and Spots Remaining */}
      <div className="open-spots-remaining">
        <p style={{ marginBottom: "-1px" }}>
          {12 - totalRowsThisWeek} spots remaining for
        </p>
        <p style={{ marginBottom: "-1px" }}>next release on {getNextMonday()} </p>
      </div>

      <div className="countdown-timer">Deadline: {countdown}</div>
    </>
  )}
</div>


{!isReleased && (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    {totalRowsThisWeek < 12 ? (
      onReleaseList ? (
        isReleaseScheduled ? (
          releaseScheduledDate ? (
            <div style={{ textAlign: "center" }}>
              <p>
                Release Call Scheduled for:{" "}
                {new Date(releaseScheduledDate).toLocaleString()}
              </p>
              <a
                href="https://aagencies-my.sharepoint.com/:b:/g/personal/kvanbibber_ariasagencies_com/Edr14iXcerVHoroIJvQd5gMB-BCqDoRpg-tzI2vVElfbDg"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    marginRight: "5px",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  QUESTIONS THAT MAY BE ASKED DURING RELEASE
                </span>
                <FaDownload />
              </a>
              <p style={{ marginTop: "10px" }}>
                <strong>Zoom Meeting Info:</strong>
              </p>
              <p>
                <a
                  href="https://zoom.us/j/6233180376"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  Join Zoom Meeting
                </a>
              </p>
              <p>Meeting ID: 623 318 0376</p>
              <p>Password: 1234</p>
            </div>
          ) : (
            <p>
              Your request to join the release call was successful. Please check
              back Saturday for your release call time.
            </p>
          )
        ) : (
          <p>
            Your request to join the release call was successful. Please check
            back Saturday for your release call time.
          </p>
        )
      ) : (
        <button
          onClick={handleScheduleReleaseCall}
          disabled={progress < 100 || onReleaseList}
          className={
            progress === 100 && !onReleaseList ? "unlocked-link" : "locked-link"
          }
        >
          <span className="link-icon">
            {progress === 100 ? <FaLockOpen /> : <FaLock />}
          </span>
          {progress === 100
            ? "Schedule Release Call"
            : "Complete All Sections to Unlock"}
        </button>
      )
    ) : (
      <p className="full-release-message">
        Release calls are full for this week. Please request scheduling for
        next week.
      </p>
    )}
  </div>
)}



      <div className="progress-bar-container">
        <div className="progress-percentage">
          {Math.round(progress)}% Complete
        </div>
        <div className="checklist-progress-bar">
          <div className="checklist-progress-bar-fill" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      
        <RoleplayModal
  isOpen={isModalOpen}
  onClose={closeModal}
  content={activeModalContent}
/>
      <div className="checklist-section-grid">
      <div className="left-column-sections">
    {Object.entries(sections).map(([section, questions]) => {
      const isPreparationSection = section === "Preparation";
      const isExpectationsSection = section === "Expectations";

      // Only render Preparation in the left column, and skip Expectations here
      if (!isPreparationSection || isExpectationsSection) return null;

      return (
        <React.Fragment key={section}>
          <div className="checklist-section-banner">
            {section.replace(/([A-Z])/g, " $1").trim()}
          </div>
          <div className="checklist-section">
            <form className="checklist-questions">
              {Object.keys(questions)
                .filter(
                  (key) =>
                    ![
                      "contract_2nd",
                      "bonus_90d",
                      "bonus_after_90d",
                      "ready_release",
                      "know_more",
                      "build_team",
                      "know_team",
                    ].includes(key)
                )
                .map((key) => (
                  <div key={key} className="prep-checklist-question-item">
                    <label>{questions[key]}</label>
                    {key === "practice_pres" || key === "refs_25" ? (
                      <div className="progress-visualization">
  <button
    type="button"
    className="increment-button"
    onClick={() =>
      handleInputChange(
        key,
        Math.max((responses[key] || 0) - 1, 0)
      )
    }
  >
    -
  </button>
  <input
    type="range"
    min="0"
    max={key === "practice_pres" ? 10 : 25}
    value={responses[key] || 0}
    onChange={(e) =>
      handleInputChange(
        key,
        Math.min(e.target.value, key === "practice_pres" ? 10 : 25)
      )
    }
    className="range-slider"
  />
  <button
    type="button"
    className="increment-button"
    onClick={() =>
      handleInputChange(
        key,
        Math.min((responses[key] || 0) + 1, key === "practice_pres" ? 10 : 25)
      )
    }
  >
    +
  </button>
  <div className="slider-labels">
    <span>0</span>
    <span>{key === "practice_pres" ? 10 : 25}</span>
  </div>
  <span className="progress-count">
    {responses[key] || 0} / {key === "practice_pres" ? 10 : 25}
  </span>
  {responses[key] === (key === "practice_pres" ? 10 : 25) && (
    <span className=""></span>
  )}
</div>

                    ) : (
                      <input
                        type="checkbox"
                        checked={responses[key] || false}
                        onChange={() => handleCheckboxChange(key)}
                      />
                    )}
                  </div>
                ))}






<hr></hr>                      
<div className="checklist-question-item">
                      <label>How long to activate your second contract?</label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                        type="number"
                        min="0"
                        style={{
                          width: "5ch",

                          borderRadius: "4px",
                          borderWidth: responses["contract_2nd"] ? "2px" : "1px",
                        }}
                        value={responses["contract_2nd"] || ""}
                        onBlur={() => handleInputBlur("contract_2nd")}
                        onChange={(e) => handleInputChange("contract_2nd", e.target.value)}
                        />
                        <span style={{ marginLeft: "5px" }}>days</span>
                      </div>
                      </div>

                      <div key="recruiting_bonus" className="checklist-question-item">
  <label>How much is the recruiting bonus?</label>
  <div className="dual-input-container" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginTop: '5px' }}>
  <div className="dual-input-header">
    <p style={{ fontSize: '12px', marginBottom: '-1px' }}>First 90 Days</p>
    <input
      type="number"
      min="0"
      style={{
        width: "10ch",
        borderRadius: "4px",
        
      }}
      value={responses["bonus_90d"] || ""}
      onBlur={() => handleInputBlur("bonus_90d")}
      onChange={(e) => handleInputChange("bonus_90d", e.target.value)}
    />
  </div>
  <div className="dual-input-header">
    <p style={{ fontSize: '12px', marginBottom: '-1px' }}>After 90 Days</p>
    <input
      type="number"
      min="0"
      style={{
        width: "10ch",
        borderRadius: "4px",
      }}
      value={responses["bonus_after_90d"] || ""}
      onBlur={() => handleInputBlur("bonus_after_90d")}
      onChange={(e) => handleInputChange("bonus_after_90d", e.target.value)}
    />
  </div>
</div>

</div>                      
<hr></hr>
                      <div className="radio-checklist-question-item" style={{ marginTop: "30px" }}>
                      <label>*Do you want to build a team?</label>
                      <div>
                        <label>
                        <input
                          type="radio"
                          name="build_team"
                          value="y"
                          checked={responses.build_team === "y"}
                          onChange={() => handleInputChange("build_team", "y")}
                        />
                        Yes
                        </label>
                        <label>
                        <input
                          type="radio"
                          name="build_team"
                          value="n"
                          checked={responses.build_team === "n"}
                          onChange={() => handleInputChange("build_team", "n")}
                        />
                        No
                        </label>
                      </div>
                      </div>

                      <div className="radio-checklist-question-item">
                      <label>*Do you know how to build a team?</label>
                      <div>
                        <label>
                        <input
                          type="radio"
                          name="know_team"
                          value="y"
                          checked={responses.know_team === "y"}
                          onChange={() => handleInputChange("know_team", "y")}
                        />
                        Yes
                        </label>
                        <label>
                        <input
                          type="radio"
                          name="know_team"
                          value="n"
                          checked={responses.know_team === "n"}
                          onChange={() => handleInputChange("know_team", "n")}
                        />
                        No
                        </label>
                      </div>
                      </div>
                      <div className="radio-checklist-question-item">
                      <label>*Do you feel ready to be released at this point?</label>
                      <div>
                        <label>
                        <input
                          type="radio"
                          name="ready_release"
                          value="y"
                          checked={responses.ready_release === "y"}
                      onChange={() => {
                        handleInputChange("ready_release", "y");
                        updateProgress({ ready_release: "y" });
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="ready_release"
                      value="n"
                      checked={responses.ready_release === "n"}
                      onChange={() => {
                        handleInputChange("ready_release", "n");
                        updateProgress({ ready_release: "n" });
                      }}
                    />
                    No
                  </label>
                </div>
              </div>
              <div key="know_more" className="checklist-question-item full-row">
  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
    <label>What would you like to know more about at this point?</label>
    <textarea
  rows="3"
  style={{ width: "100%", resize: "vertical", height: "275px", fontSize: '20px' }} // Set default height
  maxLength="250"
  value={responses["know_more"] || ""}
  onChange={(e) => handleInputChange("know_more", e.target.value)}
  onBlur={() => updateProgress({ know_more: responses["know_more"] || "" })}
/>

  </div>
</div>


            </form>
          </div>
        </React.Fragment>
      );
    })}
  </div>

  <div className="right-column-sections">
    {Object.entries(sections).map(([section, questions]) => {
      const isRolePlaySection = section === "RolePlay";
      const isVideoReviewSection = section === "VideoReview";
      const isNumbersSection = section === "Numbers";

      if (!isRolePlaySection && !isVideoReviewSection && !isNumbersSection) return null;

      return (
        <React.Fragment key={section}>
          <div className="checklist-section-banner">
            {section.replace(/([A-Z])/g, " $1").trim()}
          </div>
          <div className="checklist-section">
            {isRolePlaySection ? (
              <form className="checklist-questions">
                {Object.entries(questions).map(([key, question]) => (
                  <div key={key} className="checklist-question-item">
                    <span
                      style={{ cursor: "pointer", color: "#0000EE" }}
                      onClick={() => openModal(key)}
                    >
                      {question}
                    </span>
                    <input
                      type="checkbox"
                      checked={responses[key] || false}
                      onChange={() => handleCheckboxChange(key)}
                    />
                  </div>
                ))}
              </form>
            ) : isVideoReviewSection ? (
              <form className="checklist-questions">
                <p style={{textAlign: 'center', fontSize: '14px', color: 'red'}}>For video review either record a full presentation or have a manager sit in on a full presentation. Your manager will then review this with you and complete this section.</p>
                {Object.entries(questions).map(([key, question]) => (
                  <div key={key} className="checklist-question-item">
                    {key === "reviewed_by" ? (
                      <label>
                        {question}
                        <select
                          value={responses[key] || ""}
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          onBlur={() => handleInputBlur(key)}
                          disabled={userRole === "AGT"}
                        >
                          <option value="">Select</option>
                          {reviewerOptions.map((reviewer, index) => (
                            <option key={index} value={reviewer}>
                              {reviewer}
                            </option>
                          ))}
                        </select>
                      </label>
                    ) : (
                      <>
                        <label>{question}</label>
                        <input
                          type="checkbox"
                          checked={responses[key] || false}
                          onChange={() => handleCheckboxChange(key)}
                          disabled={userRole === "AGT"}
                        />
                      </>
                    )}
                  </div>
                ))}
              </form>
            ) : isNumbersSection ? (
              <form className="checklist-questions">
                <div className="checklist-question-item">
                  <label style={{ visibility: 'hidden' }}>Question</label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p style={{ width: "9ch", textAlign: "center", fontWeight: "bold" }}>Trainee</p>
                    <p style={{ width: "9ch", textAlign: "center", fontWeight: "bold" }}>Trainer</p>
                  </div>
                </div>

                {Object.entries(questions).map(([key, question]) => {
                  const baseLabel = question.replace(" (Trainee)", "").replace(" (Trainer)", "");
                  const traineeKey = key.replace("_trainer", "_trainee");
                  const trainerKey = key.replace("_trainee", "_trainer");

                  if (!key.endsWith("_trainee")) return null;

                  return (
                    <div key={traineeKey} className="checklist-question-item">
                      <label>{baseLabel}</label>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="text"
                          maxLength="5"
                          style={{ width: "9ch" }}
                          value={responses[traineeKey] || ""}
                          onChange={(e) => handleInputChange(traineeKey, e.target.value)}
                          onBlur={() => updateProgress({ [traineeKey]: responses[traineeKey] })}
                        />
                        <input
                          type="text"
                          maxLength="5"
                          style={{ width: "9ch" }}
                          value={responses[trainerKey] || ""}
                          onChange={(e) => handleInputChange(trainerKey, e.target.value)}
                          onBlur={() => updateProgress({ [trainerKey]: responses[trainerKey] })}
                          disabled={userRole === "AGT"}
                        />
                      </div>
                    </div>
                  );
                })}
              </form>
            ) : null}
          </div>
        </React.Fragment>
      );
    })}
  </div>

  {isReleased && (
  <div className="full-width-section">
    <div className="checklist-section-banner">Expectations</div>
    <div className="checklist-section">
      <form className="checklist-questions">
        <div className="checklist-question-item">
          <label style={{ marginRight: '15px' }}>What time do you start?</label>
          <div className="timeline-group">
            <div>
              <p style={{ textAlign: 'center', marginBottom: '0px' }}>Weekdays</p>
              <div className="timeline-radio-group">
                {["8am", "9am", "10am"].map((time) => (
                  <label key={time} className="timeline-radio-label">
                    <input
                      type="radio"
                      name="start_wkdy"
                      value={time}
                      checked={responses["start_wkdy"] === time}
                      onChange={(e) => handleInputChange("start_wkdy", e.target.value)}
                      onBlur={() => handleInputBlur("start_wkdy")}
                    />
                    <span className="timeline-radio-button">{time}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <p style={{ textAlign: 'center', marginTop: '15px', marginBottom: '0px' }}>Weekends</p>
              <div className="timeline-radio-group">
                {["8am", "9am", "10am"].map((time) => (
                  <label key={time} className="timeline-radio-label">
                    <input
                      type="radio"
                      name="start_wknd"
                      value={time}
                      checked={responses["start_wknd"] === time}
                      onChange={(e) => handleInputChange("start_wknd", e.target.value)}
                      onBlur={() => handleInputBlur("start_wknd")}
                    />
                    <span className="timeline-radio-button">{time}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        {Object.entries(sections.Expectations)
          .filter(([key]) => !["start_wkdy", "start_wknd"].includes(key)) // Exclude the start time questions
          .map(([key, question]) => (
            <div key={key} className="checklist-question-item">
              <label>{question}</label>
              <input
                type="text"
                style={{ width: "9ch" }}
                value={responses[key] || ""}
                onBlur={() => handleInputBlur(key)}
                onChange={(e) => handleInputChange(key, e.target.value)}
              />
            </div>
          ))}
      </form>
    </div>
  </div>
)}


</div>

    </div>
  );
  
  
};

export default Checklist;
